import React, { FC } from 'react';
import cn from "classnames";
import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";

import styles from './Input.module.scss';
import { FCC } from 'types';

export type InputProps = TextFieldProps & {
    // className?: string
}

const Input: FCC<InputProps> = ({className, ...props }) => {

    return (
        <TextField variant="outlined" className={cn(styles.component, className)} {...props}/>
    )
}

export default Input;
