import React, {ChangeEvent, useEffect, useState} from 'react';

import cn from 'classnames';

import { TextField, Alert} from '@mui/material';

import Button from 'components/Button/Button'
import CodeVerification from 'components/CodeVerification/CodeVerification';
import {
    apiEnterByCode, apiEnterByPassword,
    apiRegistrationByCode, apiRegistrationByPassword, apiRegistrationGetCode,
    apiRestoreAccessToAccount
} from 'api/auth';
import LoginRegistrationForm, {LoginRegistrationFormDataProps} from "./LoginRegistrationForm/LoginRegistrationForm";
import {useAuth} from 'providers/AuthProvider';
import showNotification from "../Notifications/showNotification";
import {getValidationError} from 'constants/texts';
import { i18nt } from 'translations/i18n';
import { FCC } from 'types';
import {globalLocale} from 'utils/dateUtil';

import styles from './LoginEmailForm.module.scss';

export interface LoginEmailFormProps {
    isRegistrationMode?: boolean,
    onChangeEmail: (value: string) => void;
    email: string;
}
const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const LoginEmailForm: FCC<LoginEmailFormProps> = ({ isRegistrationMode = false, onChangeEmail, ...props}) => {
    const {setUser} = useAuth();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [error, setError] = useState<string>();

    const [confirmationMode, setConfirmationMode] = useState<'password' | 'code'>()
    const [showPasswordOrCode, setShowPasswordOrCode] = useState<boolean>(false);
    const [confirmationCode, setConfirmationCode] = useState<string>();
    const [userNotCreated, setUserNotCreated] = useState<boolean>(false);

    const [registrationMode, setRegistrationMode] = useState<boolean>(isRegistrationMode);
    const [registrationData, setRegistrationData] = useState<LoginRegistrationFormDataProps>();


    useEffect(() => {
        if(isRegistrationMode !== registrationMode) {
            setRegistrationMode(isRegistrationMode)
        }
    }, [isRegistrationMode])

    useEffect(() => {
        setEmail(props.email);
    }, [props.email])

    const validateEmailState = (data: any, callback: () => void) => {
        const _email = data?.email || email;
        if(_email && validateEmail(_email)) {
            callback();
            return;
        }
        setError(i18nt('EMAIL_IS_INCORRECT') || '')
    }
    const handleSubmit = (type: 'password' | 'code') => () => {
        if(registrationMode) {
            // registrationData
            if (type === 'password' && registrationData && password) {
                // console.log('register with password');
                // console.log({...registrationData, password})
                apiRegistrationByPassword({ registrationData, password, locale: globalLocale.key})
                    .then((response) => {
                        console.log('success')
                        setUser(response.data);
                        showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')})
                    })
                    .catch((e) => {
                        const code = e?.data.errors?.[0]?.code;
                        const errorTextByCode = getValidationError(code);
                        if(errorTextByCode) {
                            showNotification({type: 'error',content: errorTextByCode});
                            return;
                        }
                        showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
                    })
            }
            if (type === 'code' && registrationData && confirmationCode) {
                // console.log('register with code');
                // console.log({...registrationData, digitCode: confirmationCode})
                apiRegistrationByCode({ registrationData, digitCode: confirmationCode, locale: globalLocale.key})
                    .then((response) => {
                        setUser(response.data);
                        showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')})
                    })
                  .catch((e) => {
                      const code = e?.data.errors?.[0]?.code;
                      const errorTextByCode = getValidationError(code);
                      if(errorTextByCode) {
                          showNotification({type: 'error',content: errorTextByCode});
                          return;
                      }
                      showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
                  })
            }
        }  else {
            if (type === 'password' && email && password) {
                console.log('auth with password');
                console.log({...registrationData, password})
                apiEnterByPassword({email, password})
                    .then((response) => {
                        console.log('success')
                        setUser(response.data);
                        showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')})
                    })
                    .catch(() => {
                        console.log('error')
                        showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
                    })
            }
            if (type === 'code' && email && confirmationCode) {
                apiEnterByCode({email, digitCode: confirmationCode})
                    .then((response) => {
                        setUser(response.data);
                        showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')})
                    })
                    .catch(() => {
                        showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
                    })
            }
        }

    }

    const handleInputChange= (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setEmail(value);
        setUserNotCreated(false);
        if(validateEmail(value) && error) {
            setError(undefined)
        }
        onChangeEmail(value)
    }

    const handleShowPassOrCode = (data: any) => {
        console.log('handleShowPassOrCode', {email})
        validateEmailState(data, () => {
            setShowPasswordOrCode(true)
        })
    }

    const getConfirmationCode = () => {
        if(registrationMode) {
            if(registrationData) {
                apiRegistrationGetCode({ registrationData, locale: globalLocale.key })
                  .then(() => {
                      setConfirmationMode('code')
                  })
                  //@ts-ignore
                  .catch((e) => {
                      if(e?.data.error.code === 'client_not_found') {
                          setUserNotCreated(true);
                      }

                      console.log(e);
                  })
            }
            return;
        }
        email && apiRestoreAccessToAccount({ email })
            .then((response) => {
                setConfirmationMode('code')
                console.log(response);
                // setShowConfiramtion(true)
            })
            //@ts-ignore
            .catch((e) => {
                if(e?.data.error.code === 'client_not_found') {
                    setUserNotCreated(true);
                    // setRegistrationMode(true);
                }

                console.log(e);
            })

    }

    const handleVerificationCode = (value: string) => {
        setConfirmationCode(value);
        console.log('handleVerificationCode', value);
    }

    const handleSubmitRegistrationData = (props: LoginRegistrationFormDataProps) => {
        setEmail(props.email);
        onChangeEmail(props.email);
        setRegistrationData(props);
        handleShowPassOrCode({email: props.email});
    }

    return (
        <div className={styles.component}>
            {/*{showConfirmation && (*/}
            {/*    <div className={styles.confirmation}>*/}
            {/*        <MarkEmailReadIcon className={styles.confirmationIcon}/>*/}
            {/*        <p>Перевірте будь-ласка пошту</p>*/}
            {/*        <p>Та авторизуйтесь за допомогую швидкої лінки</p>*/}
            {/*    </div>*/}
            {/*)}*/}

            {!showPasswordOrCode && !registrationMode && (
                <>
                <TextField
                    className={styles.input}
                    type="email"
                    label="Email"
                    value={email}
                    onChange={handleInputChange}
                    error={!!error}
                    helperText={error}
                />
                    <Button
                        variant="primary"
                        onClick={handleShowPassOrCode}
                        className={styles.enterButton}
                    >
                        {i18nt('CONTINUE')}
                    </Button>
                </>
            )}

            {!showPasswordOrCode && registrationMode && <LoginRegistrationForm email={email} onSubmit={handleSubmitRegistrationData} className={styles.LoginRegistrationForm}/>}

            {userNotCreated &&  (
                <Alert severity="warning">{i18nt('THE_CLIENT_WITH_THIS_EMAIL_DOES_NOT_EXIST_BUT_YOU_CAN_REGISTER')}</Alert>
            )}


            {showPasswordOrCode && (
                <>
                    <div className={styles.emailPreview} title={i18nt('EDIT')} onClick={() => {
                        setConfirmationMode(undefined);
                        setShowPasswordOrCode(false)
                    } }>
                        {email}
                    </div>
                    {confirmationMode !== 'password' && (
                        <Button
                            variant="primary"
                            onClick={() => setConfirmationMode('password')}
                            className={styles.enterButton}
                        >
                            {registrationMode ? i18nt('REGISTER_WITH_PASSWORD') : i18nt('LOGIN_WITH_PASSWORD')}
                        </Button>
                    )}
                    {confirmationMode === 'password' && (
                        <>
                            <TextField
                                className={styles.input}
                                type="password"
                                label={i18nt('PASSWORD')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {!!password?.length && (
                                <Button
                                    variant="primary"
                                    onClick={handleSubmit('password')}
                                    className={styles.enterButton}
                                >
                                    {registrationMode ? i18nt('REGISTER') : i18nt('LOGIN')}
                                </Button>
                            )}
                        </>
                    )}


                    <div className={styles.orBlock}><i/><span>{i18nt('OR')}</span><i/></div>

                    {confirmationMode !== 'code' && (
                        <Button
                            variant="primary"
                            onClick={getConfirmationCode}
                            className={styles.enterButton}
                        >
                           {registrationMode ? i18nt('REGISTER_WITH_CODE') : i18nt('LOGIN_WITH_CODE')}
                        </Button>

                    )}
                    {confirmationMode === 'code' && (
                        <>
                            <div>
                                <p className={styles.helpText}>{i18nt('THE_CODE_WAS_SENT_TO_THE_SPECIFIED_EMAIL')}</p>
                            </div>
                            <CodeVerification onComplete={handleVerificationCode}/>

                            {confirmationCode && (
                                <Button
                                    variant="text"
                                    onClick={handleSubmit('code')}
                                    className={cn(styles.enterButton, styles.confirmationCodeButton)}
                                >
                                    {registrationMode ? i18nt('REGISTER') : i18nt('LOGIN')}
                                </Button>
                            )}
                        </>
                    )}

                </>
            )}
        </div>
    )
}

export default LoginEmailForm;
