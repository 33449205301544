import React, {ElementType, ReactNode, MouseEvent} from 'react';
import { FCC } from 'types';
import cn from "classnames";
import { Link } from 'react-router-dom';
import {Button as MUIButton} from "@mui/material";
import Tooltip from 'components/Tooltip/Tooltip';
import styles from './Button.module.scss';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import {IconsMapType} from 'assets/icons/icons';


type BUTTON_VARIANT = 'primary' | 'secondary' | 'text';
type BUTTON_ELEMENT = 'button' | 'a' | 'link';

export type ButtonProps<V extends BUTTON_ELEMENT = 'button'> = {
    component?: V;
    isLoading?: boolean;
    disabled?: boolean;
    // {null} needed to turn off icon for prefilled button wrappers (AddButton, DeleteButton, etc.)
    startIcon?: keyof IconsMapType | null;
    startIconClassName?: string;
    // {null} needed to turn off icon for prefilled button wrappers (AddButton, DeleteButton, etc.)
    endIcon?: keyof IconsMapType | null;
    endIconClassName?: string;
    variant?: BUTTON_VARIANT;
    label?: string;
    className?: string;
    children?: ReactNode;
    isActive?: boolean;
    name?: string;
    dataId?: string;
    defaultFormWidth?: boolean;
    tooltipTitle?: ReactNode;
    type?: 'button' | 'submit' | 'reset';
} & (V extends 'button'
  ? {
      onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
      href?: never;
      to?: never;
  }
  : V extends 'a'
    ? {
        onClick?: never;
        href?: string;
        target?: string;
        to?: never;
    }
    : V extends 'link'
      ? {
          component: 'link';
          onClick?: never;
          to: string;
          target?: string;
      }
      : {});

function Button<V extends BUTTON_ELEMENT>({
                                              label,
                                              component = 'button' as V,
                                              className,
                                              variant = 'primary',
                                              startIcon,
                                              startIconClassName,
                                              endIcon,
                                              endIconClassName,
                                              disabled,
                                              isLoading,
                                              children,
                                              isActive,
                                              dataId,
                                              defaultFormWidth,
                                              tooltipTitle,
                                              ...props
                                          }: ButtonProps<V>) {
    const componentElement = component === 'link' ? Link : component;
    const showTooltip = tooltipTitle && disabled && !isLoading;
    const showTooltipText = showTooltip && tooltipTitle;

    return (
      <Tooltip content={showTooltipText}>
          <MUIButton
            component={componentElement as ElementType}
            className={cn(
              'Button_component',
              styles.component,
              variant && styles[variant],
              isLoading && styles.loading,
              disabled && styles.disabled,
              isActive && styles.isActive,
              className,
              defaultFormWidth && styles.defaultFormWidth,
              showTooltipText && styles.showTooltipText,
            )}
            disabled={disabled || isLoading}
            data-id={dataId}
            {...props}
            // style={disabled ? { pointerEvents: 'none' } : {}}
          >
              {startIcon && <Icon name={startIcon} className={cn(styles.startIcon, startIconClassName)}/>}
              {(label || children) && <span className={styles.label}>{label || children}</span>}
              {endIcon && <Icon name={endIcon} className={cn(styles.endIcon, endIconClassName)}/>}
              {isLoading && <Spinner/>}
          </MUIButton>
      </Tooltip>
    );
}

export default Button;