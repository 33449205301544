import React from 'react';
import cn from 'classnames';

import styles from './MapEmbed.module.scss';

interface Props {
  className?: string;
  mapAddress?: string;
  allowFullScreen?: boolean;
}

const KK = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapEmbed = ({ mapAddress, className, allowFullScreen }: Props) => (
    <div className={cn(styles.component, className)}>
      <iframe
        loading="lazy"
        // allowFullScreen={allowFullScreen}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=${KK}&q=${mapAddress}`}
      />
    </div>
  )

export default MapEmbed;
