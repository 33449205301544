import React, {useMemo} from 'react';
import {FCC} from 'types';
import SlickSlider from 'react-slick';

import "./slick.css";
// import "./slick-theme.css";
import styles from './Slider.module.scss';
import cn from 'classnames';
import Icon from 'components/Icon/Icon';

interface Props {
  className?: string;
}

const DEFAULT_SETTINGS = {
  dots: true,
}

// const SliderNavButton: FCC<any> = ({children}) => (
//   <div><div className={styles.arrowXXX}>{children}</div></div>
// )

const Slider: FCC<Props> = ({className, children}) => {

  const childrenArray: any[] = useMemo(() => React.Children.toArray(children), [children]);

  return (
    <SlickSlider {...DEFAULT_SETTINGS}
                 arrows={childrenArray.length > 1}
                 // prevArrow={<SliderNavButton>Prev Arrow</SliderNavButton>}
                 // nextArrow={<SliderNavButton>Next Arrow</SliderNavButton>}
                 prevArrow={<div><div className={styles.arrow}><Icon name="chevronLeft"/></div></div>}
                 nextArrow={<div><div className={styles.arrow}><Icon name="chevronRight"/></div></div>}

                 className={cn(styles.component, className)}>
      {childrenArray.map((child) => (
          <div key={child.key}>{child}</div>
        )
      )}
    </SlickSlider>
  );
}

export default Slider;

