import React, {useContext, useEffect, useMemo, useState} from 'react';
import {FCC} from 'types';
import StoreProviderContext from './StoreProviderContext';
import {getAvailableTimeslots} from 'utils/getTimeslots';
import {
  ContactsDataType,
  OrderData,
  OrderDataExtended,
  ServicesDTO,
  ServiceSubItemDTO,
  SpecialistDTO,
  TTimeSlot,
} from 'components/types';
import {getEmployeesApi} from 'api/employees';
import {getServicesListApi} from 'api/services';
import {format} from 'date-fns';
import {apiSubmitOrder} from 'api/order';
import {useOrganization} from 'providers/OrganizationProvider';
import _ from 'lodash';
import useLoading from 'hooks/useLoading';
import {isSingleService} from 'utils/storeUtils';
import showNotification from 'components/Notifications/showNotification';
import {i18nt} from 'translations/i18n';
import {useAuth} from 'providers/AuthProvider';

export type OrderDataType = {
  date: Date | null,
  time: TTimeSlot | null,
  specialist?: SpecialistDTO | null,
  services: ServiceSubItemDTO[],
}

export type OrderDataOptionalType = {
  date?: Date | null,
  time?: TTimeSlot | null,
  specialist?: SpecialistDTO | null,
  services?: ServiceSubItemDTO[],
}

export const INITIAL_ORDER_DATA = {
  date: null,
  time: null,
  specialist: undefined,
  services: [],
}

const StoreProvider: FCC = ({
                              children,
                            }) => {
  const {organization, navigateToOrganizationPage} = useOrganization();
  const {user} = useAuth();

  const singleEmployee = organization?.singleEmployee;
  const singleService = organization?.singleService;
  const shortOptionsInitialData = (singleEmployee || singleService) ? {
    ...INITIAL_ORDER_DATA,
    specialist: singleEmployee || undefined,
    services: singleService ? [singleService] : [],
  } as OrderDataType : null;
  const orderInitialData = shortOptionsInitialData || INITIAL_ORDER_DATA

  // TODO: During realization of feedback page was decided to remove short options (but decided to left code for future)
  // const shortOptions = Boolean(shortOptionsInitialData);
  const shortOptions = false;

  const [orderData, setOrderData] = useState<OrderDataType>(orderInitialData)

  const {date, time, specialist, services} = orderData;

  // view data
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);

  const [specialistsList, setSpecialistsList] = useState<SpecialistDTO[]>([]);
  const [specialistsListIsLoading, setSpecialistsListIsLoading] = useLoading(true);

  const [servicesList, setServicesList] = useState<ServicesDTO>([])
  const [servicesListIsLoading, setServicesListIsLoading] = useLoading(true);

  const [successOrder, setSuccessOrder] = useState<OrderDataExtended>()

  const transitionToRoute = (props?: any) => {

    // inner data
    const _time = props?.time || time;
    const _date = props?.date || date;
    const _services = props?.services || services;
    const _specialist = props?.specialist || specialist;

    // helpers
    const dateAndTime = _date && _time;
    const specialistSelected = _specialist !== undefined;
    const servicesSelected = _services.length > 0

    if (date && !_time) {
      navigateToOrganizationPage('time')
    } else if (dateAndTime && specialistSelected && servicesSelected) {
      navigateToOrganizationPage('contacts')
    } else if (dateAndTime && specialistSelected) {
      navigateToOrganizationPage('services')
    } else if (dateAndTime && _services.length > 0) {
      navigateToOrganizationPage('specialists')
    } else if (!dateAndTime && !specialistSelected && servicesSelected) {
      navigateToOrganizationPage('date')
    } else {
      navigateToOrganizationPage()
    }
  }

  useEffect(() => {
    getServicesLists();
  }, [organization, time, specialist, user])

  useEffect(() => {
    setAvailableTimeSlots(getAvailableTimeslots())
  }, [date, user])

  //specialists list
  useEffect(() => {
    getSpecialistsLists();
  }, [organization, time, services, user])


  const updateDateByDay = (date: Date | null) => {
    setOrderData({...orderData, date})
  }

  const setDate = (value: Date | null) => {
    const newData = {...orderData, date: value};
    setOrderData(newData);
  }
  const setTime = (value: TTimeSlot | null) => {
    const newData = {...orderData, time: value};
    setOrderData(newData);
  }

  const setSpecialist = (value: SpecialistDTO | null) => {
    const newData = {...orderData, specialist: value};
    setOrderData(newData);
  }

  const setServices = (value: ServiceSubItemDTO[]) => {
    const newData = {...orderData, services: value};
    setOrderData(newData);
  }


  // set data with transition
  const handleDate = (value: Date | null) => {
    const newData = {...orderData, date: value};
    setOrderData(newData);
    transitionToRoute(newData)
  }
  const handleTime = (value: TTimeSlot | null) => {
    const newData = {...orderData, time: value};
    setOrderData(newData);
    transitionToRoute(newData)
  }

  const handleSpecialist = (value: SpecialistDTO | null) => {
    const newData = {...orderData, specialist: value};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleService = (value: ServiceSubItemDTO[]) => {
    const newData = {...orderData, services: value};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleOrderData = (data: OrderDataOptionalType) => {
    const newData = {...orderData, ...data};
    setOrderData(newData);
    transitionToRoute(newData);
  }

  const handleOrderDataNoTransition = (data: OrderDataOptionalType) => {
    const newData = {...orderData, ...data};
    setOrderData(newData);
  }

  // data
  const getServicesLists = () => {
    if (!organization) return;
    setServicesListIsLoading(true);
    const query = [];
    if (date && time) query.push(`datetime=${format(new Date(date), 'yyyy-MM-dd')}T${time}:00`)
    if (specialist) query.push(`employee_id=${specialist.id}`);

    getServicesListApi({organizationId: organization.id, queryString: query.join('&')}).then(result => {
      setServicesList(result?.data);
      setServicesListIsLoading(false);
    })

  };

  const getSpecialistsLists = () => {
    if (!organization) return;
    setSpecialistsListIsLoading(true);
    const query: string[] = [];
    if (date && time) query.push(`datetime=${format(new Date(date), 'yyyy-MM-dd')}T${time}:00`);
    if (services.length > 0) query.push(`services_ids=${services.map(item => item.id).join(',')}`);

    getEmployeesApi({organizationId: organization.id, queryString: query.join('&')}).then(result => {
      setSpecialistsList(result?.data);
      setSpecialistsListIsLoading(false);
    })
  }

  const resetLoadedOptions = () => {
    console.log('resetLoadedOptions');
    getSpecialistsLists();
    getServicesLists();
    setAvailableTimeSlots(getAvailableTimeslots())
  }

  const submitUserOrder = (data: ContactsDataType & { locale: string }) => {
    if (date && time && services && organization) {
      const employee_id = specialist === null ? null : (specialist?.id || null)
      const fullOrderData: OrderData & { locale: string } = {
        contactDetails: data,
        date: format(new Date(date), 'yyyy-MM-dd'),
        time,
        employee_id,
        services_ids: services.map(service => service.id),
        locale: data.locale,
      }

      return apiSubmitOrder(fullOrderData, organization.id)
        .then(response => {
        setSuccessOrder(response?.data);
        resetForm();
        navigateToOrganizationPage(`order/${response?.data?.hash}`);
        showNotification({
          type: 'success',
          content: i18nt('ORDER_CREATION_SUCCESS')
        });
      })
        .catch((error) => {

          showNotification({
            type: 'error',
            content: i18nt('ORDER_CREATION_ERROR')
          });
          throw error;
        })
    }

    showNotification({
      type: 'error',
      content: i18nt('ORDER_CREATION_ERROR')
    });

    return Promise.reject();

  };

  const resetForm = () => {
    setOrderData(orderInitialData);
  }

  //handle shortOptions
  useEffect(() => {
    if (specialistsList.length === 1 && specialist?.id !== specialistsList?.[0].id) {
      setSpecialist(specialistsList[0])
    }

    if (isSingleService(servicesList) && !_.isEqual(services, [servicesList[0]])) {
      setServices(servicesList as ServiceSubItemDTO[])
    }
  }, [specialistsList, servicesList])


  /**
   * Problem:
   * we have 5 services [service1, service2, service3, service4, service5]
   * and 2 employees [employee_1, employee_2]
   *
   * employee_1 can do [service1, service2, service3]
   * employee_2 can do [service3, service4, service5]
   *
   * if user select service3 - we show all services as available because this service can do any employee,
   * but when user select service4 - we need to show only employee_2 services
   * because we can't select two employees in one order
   * */
  const servicesListWithPossibleOptionsByEmployee = useMemo(() => {
    const selectedServicesIds = services.map(service => service.id);
    if(selectedServicesIds.length === 0) return servicesList;

    const specialistServicesArrays: number[][] = specialistsList.map(employee => employee.services.reduce((acc,service) => {
      if(service.type === 'item') {
        return [...acc, service.id];
      }

      if(service.type === 'category') {
        return [...acc, ...service.items.map(item => item.id)];
      }

      return acc;
    }, [] as number[]));

    let availableServicesItemsIds: number[] = [];
    specialistServicesArrays.forEach((servicesBySpecialist) => {
      const allServicesAvailable = selectedServicesIds.every(serviceId => servicesBySpecialist.includes(serviceId));

      if(allServicesAvailable) {
        availableServicesItemsIds = [...availableServicesItemsIds, ...servicesBySpecialist]
      }
    })

    return servicesList.map(service => {
      if(service.type === 'item') {
        return {
          ...service,
          notAvailableByOneEmployee: !availableServicesItemsIds.includes(service.id)
        }
      }

      return {
        ...service,
        notAvailableByOneEmployee: !service.items.every(item => availableServicesItemsIds.includes(item.id)),
        items: service.items.map(item => {
          return {
            ...item,
            notAvailableByOneEmployee: !availableServicesItemsIds.includes(item.id)
          }
        }),
      }
    });
  }, [servicesList, specialistsList, services]);

  const value = useMemo(
    () => ({
      date,
      setDate,
      handleDate,

      time,
      setTime,
      handleTime,

      specialist,
      setSpecialist,
      handleSpecialist,

      servicesList: servicesListWithPossibleOptionsByEmployee,
      services,
      setServices,
      handleService,

      // data API
      specialistsList,
      availableTimeSlots,

      submitUserOrder,

      updateDateByDay,

      handleOrderData,
      handleOrderDataNoTransition,

      successOrder,
      setSuccessOrder,
      transitionToRoute,

      shortOptions,
      isLoading: specialistsListIsLoading || servicesListIsLoading,

      setOrderData: (data: OrderDataType) => setOrderData(data),
      resetOrderListData: resetForm,
      singleEmployee,
      singleService,

      resetLoadedOptions,
    }),
    [
      date,
      setDate,
      handleDate,

      time,
      setTime,
      handleTime,

      specialist,
      setSpecialist,

      servicesList,
      servicesListWithPossibleOptionsByEmployee,
      services,
      setServices,
      handleService,

      // data API
      availableTimeSlots,
      specialistsList,

      submitUserOrder,

      updateDateByDay,

      handleOrderData,
      handleOrderDataNoTransition,

      successOrder,
      setSuccessOrder,
      transitionToRoute,

      setOrderData,
      resetForm,

      resetLoadedOptions,

      shortOptions,
      singleEmployee,
      singleService,
    ],
  );

  return <StoreProviderContext.Provider value={value}>{children}</StoreProviderContext.Provider>;
};

export const useStoreProviderContext = () => useContext(StoreProviderContext);

export default StoreProvider;
