import OrganizationProvider from 'providers/OrganizationProvider';
import StoreProvider from 'providers/StoreProvider/StoreProvider';
import styles from 'components/Routing/Routing.module.scss';
import { Route, Routes } from 'react-router-dom';
import HomePage from 'components/pages/HomePage/HomePage';
import UserOrdersPage from 'components/pages/UserOrdersPage/UserOrdersPage';
import TimeSlots from 'components/pages/TimeSlots/TimeSlots';
import ContactsForm from 'components/ContactsForm/ContactsForm';
import OrderDetailsPage from 'components/pages/OrderDetailsPage/OrderDetailsPage';
import LayoutFooter from 'components/LayoutFooter/LayoutFooter';
import React from 'react';
import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";
import OrderRoutesPage from 'components/pages/OrderRoutesPage/OrderRoutesPage';
import EmployeePage from 'components/pages/EmployeePage/EmployeePage';
import FeedbackPage from 'components/pages/FeedbackPage/FeedbackPage';
import OrderMakeFooterGlobal from 'components/OrderMakeFooter/OrderMakeFooterGlobal';


const InnerRouting = () => {
  const { shortOptions } = useStoreProviderContext();

  return (
    <>
      <div className={styles.InnerPagesContent}>
        <Routes>
          <Route
            path="/about"
            element={<HomePage/>}
          />
          <Route
            path="/orders"
            element={<UserOrdersPage/>}
          />
          <Route
            path="/time"
            element={<TimeSlots />}
          />
          <Route
            path="/contacts"
            element={<ContactsForm />}
          />
          <Route
            path="/orders"
            element={<UserOrdersPage />}
          />
          <Route
            path="/orders/:orderHash"
            element={<OrderDetailsPage />}
          />
          <Route
            path="/order/:orderHash"
            element={<OrderDetailsPage standalonePage />}
          />
          <Route
            path="/feedback/:orderHash"
            element={<FeedbackPage />}
          />
          <Route
            path="/orders/:orderHash/edit/*"
            element={<OrderRoutesPage shortOptions={shortOptions}/>}
            />

          <Route
            path="specialists/:employeeId"
            element={<EmployeePage/>}
          />

          <Route
            path="*"
            element={<OrderRoutesPage shortOptions={shortOptions}/>}
          />
        </Routes>
      </div>
      <LayoutFooter/>
      <OrderMakeFooterGlobal/>
    </>
  )
}
const InnerPages = () => {
  return (
    <OrganizationProvider>
      <StoreProvider>
        <div className={styles.InnerPages}>
          <InnerRouting/>
        </div>
      </StoreProvider>
    </OrganizationProvider>
  );
}

export default InnerPages;