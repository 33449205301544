import React, { FC, useState } from 'react';
import cn from 'classnames';
import i18n from 'i18next';
import Icon from 'components/Icon/Icon';

import styles from './LanguageButton.module.scss';
import LanguageDialog from 'components/Language/LanguageDialog/LanguageDialog';
import { FCC } from 'types';


interface LanguageButtonProps {
  className?: string;
  languageClassName?: string;
}


const LanguageButton: FCC<LanguageButtonProps> = ({className, languageClassName}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);


  return (
    <div className={cn(styles.component, className)}>
      <div className={styles.iconWrapper} onClick={() => setModalIsOpen(true)}>
        <Icon name="world" className={styles.icon} />
        <div className={styles.language}>
          <span className={languageClassName}>{i18n.resolvedLanguage === 'uk' ? 'ua' : i18n.resolvedLanguage}</span>
        </div>
      </div>

      <LanguageDialog
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </div>
  )
}

export default LanguageButton;