import React, { useMemo, useState} from 'react';
import queryString from 'query-string';

import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import TextArea from 'components/TextArea/TextArea';
import Rating from '@mui/material/Rating';
import Button from 'components/Button/Button';
import showNotification from 'components/Notifications/showNotification';
import {apiPostFeedback} from 'api/feedback';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {i18nt} from 'translations/i18n';
import {useOrganization} from 'providers/OrganizationProvider';

import styles from './FeedbackPage.module.scss';

const FeedbackPage = () => {
  const { loadOrganizations, navigateToOrganizationPage } = useOrganization();
  const navigate = useNavigate();
  const location = useLocation();
  const urlQuery = queryString.parse(location.search);
  const params = useParams<{organizationId: string, orderHash: string}>();
  const {organizationId, orderHash, backRef} = useMemo(() => ({
    organizationId: Number(params.organizationId),
    orderHash: params.orderHash,
    backRef: urlQuery.backRef as string,
  }), [params, urlQuery]);


  const [feedback, setFeedback] = useState<string>('');
  const [rating, setRating] = useState<number | null>(null);

  const redirectBack = () => {
    backRef ? navigate(backRef) : navigateToOrganizationPage();
  }
  if(!orderHash || !organizationId) {
    return  null;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if(rating) {
      const data = {
        mark: rating,
        text: feedback,
      }

      apiPostFeedback({orderHash, organizationId, data})
        .then(() => {
          showNotification({type: 'success', content: i18nt('THANK_YOU_FOR_YOUR_FEEDBACK')});

          redirectBack();
          // to update organization information with feedbacks and reload employees information about feedbacks
          loadOrganizations();
        })
        .catch(() => {
          showNotification({type: 'error', content: i18nt('THERE_WAS_AN_ERROR_ADDING_FEEDBACK')});
        });

      return;
    }

    showNotification({type: 'error', content: i18nt('PLEASE_RATE')});

  }

  return (
    <div className={styles.component}>
      <OrderPageHeader backButton={{onClick: redirectBack}} title="Feedback" />

      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.ratingBlock}>
          <span>{i18nt('YOUR_RATING')}</span>
          <Rating name="no-value" value={rating} onChange={(event, value) => {
            setRating(value);
          }}/>
        </div>
        <TextArea
          label={i18nt('YOUR_REVIEW')}
          value={feedback}
          onChange={(event) => setFeedback(event.target.value)}
        />
        <div className={styles.footer}>
          <Button variant="primary" type="submit">{i18nt('SUBMIT_REVIEW')}</Button>
        </div>
      </form>

    </div>
  )
}

export default FeedbackPage;
