import React, {FC} from 'react';
import Select, {ISelect, ISelectOption} from "../Select/Select";
import cn from "classnames";
import { i18nt } from 'translations/i18n';
import { FCC } from 'types';

const HOUR_IN_MINUTES = 60;
export const DEFAULT_REMINDER_VALUE = HOUR_IN_MINUTES;

export interface IReminderSelect {
    className?: string;
    onChange: ISelect['onChange'];
    value?: ISelectOption['value'];
}

const generateOptions = () => {
    return new Array(10).fill(1).map((item, index) => {
        const hour = index + 1;
        return {value: HOUR_IN_MINUTES * hour, label: i18nt('BEFORE_X_HOURS', { hour })}
    })
};



const ReminderSelect: FCC<IReminderSelect> = ({ className,onChange, value}) => {
    const OPTIONS = generateOptions();

    return <Select
        className={cn('ReminderSelect_component',className)}
        options={OPTIONS}
        onChange={onChange}
        value={value}
    />
}

export default ReminderSelect;