import {TTimeSlot} from "../components/types";

const getTimeslots = () => {
    const slots: TTimeSlot[] = [];

    for(let i = 0; i < 24; i++) {
        const hours = `${i}`;
        const formattedHours = hours.length < 2 ? `0${hours}` : hours;

        slots.push(`${formattedHours}:00`);
        slots.push(`${formattedHours}:30`);
    }
    slots.push(`24:00`);

    return slots;
}

export function randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getAvailableTimeslots = () => {
    const _mock_available_dates = randomIntFromInterval(0,30);
    let timeSlots = getTimeslots();
    const availableTimeslots = []
    for(let i = 0; i < _mock_available_dates; i++) {
        // console.log(timeSlots);
        // console.log(randomIntFromInterval(0,timeSlots.length - 1), 0,timeSlots.length - 1);
        const timeSlotValue = timeSlots[randomIntFromInterval(0,timeSlots.length - 1)]
        timeSlots = timeSlots.filter(i => i !== timeSlotValue);
        availableTimeslots.push(timeSlotValue)
    }
    return availableTimeslots.sort();
}

export default getTimeslots;