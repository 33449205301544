import React from 'react';
import cn from 'classnames';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import styles from './OrderStatus.module.scss';
import { FCC } from 'types';
import {i18nt} from 'translations/i18n';


export interface IOrderStatus {
    isActive?: boolean,
    onClick?: () => void
}

const OrderStatus: FCC<IOrderStatus> = ({isActive = false, onClick}) => {

    if(!isActive) return null;

    const handleClick = (e: any) => {
        //@ts-ignore
        e.stopPropagation();
        onClick?.();
    }
    return (
        <div className={cn(styles.component,{[styles.isActive]: isActive})} onClick={handleClick}>
            <RemoveCircleIcon/>
            <span>
               {i18nt('RESET_ORDER_ITEM_OPTION')}
            </span>
        </div>
    )
}

export default OrderStatus;
