import {CredentialResponse} from '@react-oauth/google';

import api from "api/api";
import {UserDTO} from 'providers/AuthProvider';
import {LoginRegistrationFormDataProps} from 'components/LoginEmailForm/LoginRegistrationForm/LoginRegistrationForm';

import {ApiResponse} from "./apiTypes";

export const apiRestoreAccessToAccount = ({email} : {email: string}): Promise<ApiResponse<any>> => api.post(`/restore_access_to_account`, { email });


export const apiCheckCodeForRestoreClientAccess = ({email, digitCode } : {email: string, digitCode: string}): Promise<ApiResponse<UserDTO>> => api.post(`/check_code_for_restore_access_to_account`, { email, digitCode });
export const apiGetUserData = (): Promise<ApiResponse<any>> => api.get(`/client`);

export const apiEnterByCode = ({email, digitCode } : {email: string, digitCode: string}): Promise<ApiResponse<UserDTO>> => api.post(`/check_code_for_restore_access_to_account`, { email, digitCode });
export const apiEnterByPassword = ({email, password } : {email: string, password: string}): Promise<ApiResponse<UserDTO>> => api.post(`/restore_access_to_account_by_password`, { email, password });

export const apiRegistrationByCode = ({registrationData, digitCode, locale } : {registrationData: LoginRegistrationFormDataProps, digitCode: string, locale: string}): Promise<ApiResponse<UserDTO>> => api.post(`/check_code_for_restore_access_to_account`, { ...registrationData, digitCode, locale });
export const apiRegistrationByPassword = ({registrationData, password, locale } : {registrationData: LoginRegistrationFormDataProps, password: string, locale: string}): Promise<ApiResponse<UserDTO>> => api.post(`/registration_by_password`, { ...registrationData, password, locale });
export const apiRegistrationGetCode = ({registrationData, locale} : {registrationData: LoginRegistrationFormDataProps, locale: string }): Promise<ApiResponse<UserDTO>> => api.post(`/registration_by_code`, { ...registrationData, locale });


// export const apiCheckAuthByCode = ({email, digitCode } : {email: string, digitCode: string}): Promise<ApiResponse<UserDTO>> => api.post(`/check_code_for_restore_access_to_account`, { email, digitCode });
// api/check_code_for_restore_access_to_account


//social auth
export const apiAuthByGoogle = (data: CredentialResponse & {locale: string}): Promise<ApiResponse<UserDTO>> => api.post(`/google_auth`, data);
