import React, { FC, useEffect } from 'react';
import { FCC } from 'types';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import styles from './Layout.module.scss'

const Layout: FCC = ({ children }) => {
    return (
        <div className={cn('Layout_component', styles.component)}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default withTranslation()(Layout);