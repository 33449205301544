import React, { FC } from 'react';
import cn from 'classnames';

import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";

import styles from './OrderMakeFooter.module.scss';
import { i18nt } from 'translations/i18n';
import { FCC } from 'types';
import Button from 'components/Button/Button';

export interface OrderMakeFooterProps {
  className?: string
}

const OrderMakeFooter: FCC<OrderMakeFooterProps> = ({ className }) => {

    const {services, transitionToRoute,  time, specialist}  = useStoreProviderContext();

    const sum = services?.reduce((acc, item) => acc + item.price, 0);
    const currency = services?.[0]?.currency;

    if(!services?.length) return  null;

    return (
        <div className={cn(styles.component, className)}>
            <div>
                {i18nt('SELECTED')} ({services.length}) :
                <span className={styles.summaryPrice}>{sum} {currency}</span>
            </div>
            <div>
                <Button variant="primary" onClick={transitionToRoute} className={styles.submitButton}>
                  {!time || !specialist ? i18nt('NEXT') : i18nt('TO_ORDER')}
                </Button>
            </div>
        </div>
    )
}

export default OrderMakeFooter;
