import React, { useContext, useState } from 'react';
import { FCC } from 'types';
import _ from 'lodash';

type AppStateContextType = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isSingleOrganization: boolean;
  setIsSingleOrganization: (value: boolean) => void;
};
const AppStateProviderContextInitialState = {
  isLoading: true,
  setIsLoading: _.noop,
  isSingleOrganization: false,
  setIsSingleOrganization: _.noop,
};

const AppStateContext = React.createContext<AppStateContextType>(
  AppStateProviderContextInitialState
);

// const useOrganizations = () => {
//   const params = useParams();
//   // const [organization, setOrganization] =
//   // const [organizations, setOrganizations] = useState<OrganizationType[]>([]);
//   const { data: organizations, isLoading, forceRequest } = useApi<
//     OrganizationType[]
//     >(getOrganizationsApi);
//
//   const findOrganizationByUrlOrganizationId = (organizations: OrganizationType[]) => {
//     const organizationId = params?.organizationId;
//     if(organizationId) {
//       const organization = organizations.find(organization => organization.id === Number(organizationId));
//     }
//   }
//   // check url and if it has organization url go to irganization inner page
//   const goToOrganization = () => {
//
//   }
//   useEffect(() => {
//     organizations && findOrganizationByUrlOrganizationId(organizations);
//   }, [organizations]);
//
//   return {
//     organizations,
//     isLoading,
//     forceRequest,
//   }
// }


const AppStateProvider: FCC = ({ children }) => {
  const [isSingleOrganization, setIsSingleOrganization] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // const {organizations, forceRequest} = useOrganizations();

  const handleSetOrganization = (value: boolean) => setIsSingleOrganization(value);
  const handleSetIsLoading = (value: boolean) => setIsLoading(value);

  const value = {
    isLoading,
    setIsLoading: handleSetIsLoading,
    isSingleOrganization,
    setIsSingleOrganization: handleSetOrganization,
  };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);

export default AppStateProvider;
