import React from 'react';
import {FCC} from 'types';
import {useNavigate} from 'react-router-dom';

import OrderList from 'components/OrderList/OrderList';
import {useOrganizations} from 'providers/OrganizationsProvider';

import OrderPageHeader from '../OrderPageHeader/OrderPageHeader';

import styles from './OrderPageList.module.scss';


const OrderPageList: FCC = () => {
  const navigate = useNavigate();
  const {isSingleOrganization} = useOrganizations();

  const backButtonProps = isSingleOrganization ? undefined : {
    defaultBackButton: true,
    backButton: {
      onClick: () => navigate('/')
    }
  }

  return (
    <div className={styles.component}>
      <OrderPageHeader {...backButtonProps}/>
      <OrderList/>
    </div>
  )
}

export default OrderPageList;