import React from 'react';
import { FCC } from 'types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { updateLocale } from 'utils/dateUtil';
import { LANGUAGES_OPTIONS } from 'utils/utils';
import { i18nt } from 'translations/i18n';


import styles from './LanguageDialog.module.scss'
// import Icon from 'components/Icon/Icon';


interface LanguageDialogProps {
  open?: boolean,
  className?: string;
  onClose: () => void;
}

const LanguageDialog: FCC<LanguageDialogProps> = ({ open = false, onClose}) => {
  const { i18n } = useTranslation();

  const handleClick = (language: string) => () => {
    updateLocale(language);
    onClose();
  }

  // const getLanguageSupportedKey = (languageKey: string) => {
  //   return languageKey.toLowerCase() === 'ua' ? 'UK' : languageKey;
  // }
  const getLanguage = (languageKey: string) => {
    const languageLocalKey = languageKey.toLowerCase() === 'ua' ? 'UK' : languageKey;
    //@ts-ignore
    const lang = new Intl.DisplayNames([languageLocalKey], {type: 'language'})

    return lang.of(languageLocalKey)
  }

  // const capitalize = (s: string) => {
  //   return  s.charAt(0).toUpperCase() + s.slice(1);
  // }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      className={styles.component}
    >
      <DialogTitle>{i18nt('CHOOSE_LANGUAGE')}</DialogTitle>
      <DialogContent dividers className={styles.dialogContent}>
        {LANGUAGES_OPTIONS.map(({value: languageKey}) => (
          <div className={cn(styles.item, i18n.resolvedLanguage === languageKey && styles.selected)} onClick={handleClick(languageKey)} key={`language-${languageKey}`}>

            {/*<Icon*/}
            {/*  //@ts-ignore*/}
            {/*  name={`flag${capitalize(languageKey)}`}*/}
            {/*/>*/}
            {getLanguage(languageKey)}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {i18nt('CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LanguageDialog;