import React, {FC} from 'react';

import OrderPageHeader from "../../../OrderPageHeader/OrderPageHeader";
import HeaderArrowNavigation from "../../../HeaderArrowNavigation/HeaderArrowNavigation";
import { dateFormat } from 'utils/dateUtil';

import styles from './CalendarNavigation.module.scss'

export interface ICalendarNavigation {
    monthDate?: Date;
    date: Date;
    changeYear?: (year: number) => void;
    changeMonth?: (month: number) => void;
    customHeaderCount?: number;
    decreaseMonth?: () => void;
    increaseMonth?: () => void;
    prevMonthButtonDisabled?: boolean;
    nextMonthButtonDisabled?: boolean;
    decreaseYear?: () => void;
    increaseYear?: () => void;
    prevYearButtonDisabled?: boolean;
    nextYearButtonDisabled?: boolean;
    handleBackClick?: () => void
}

const CalendarNavigation: FC<ICalendarNavigation> = ({date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, handleBackClick}) => {

    return (
        <div className={'CalendarNavigation_component'}>
            <OrderPageHeader defaultBackButton backButton={{ onClick: () => handleBackClick?.()}}>
                <HeaderArrowNavigation
                    prevButton={{
                        onClick: decreaseMonth,
                        disabled: prevMonthButtonDisabled
                    }}
                    nextButton={{
                        onClick: increaseMonth,
                        disabled: nextMonthButtonDisabled
                    }}
                >
                    <span className={styles.monthName}>{dateFormat(date, 'LLLL')}</span>
                </HeaderArrowNavigation>
            </OrderPageHeader>
        </div>
    )
}

export default CalendarNavigation;