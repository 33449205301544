import LogRocket from 'logrocket';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import FingerprintJS from 'utils/fingerprint.js';
import {UserDTO} from 'providers/AuthProvider';

const isDevelopmentMode = () => process.env.NODE_ENV === 'development';
const isIframe = window.self !== window.top;
const parentWindowUrl = document.referrer;

const startedInsideCabinet = isIframe && parentWindowUrl?.includes('gil.com.ua');
const logrocketOff = isDevelopmentMode() || process.env.REACT_APP_URL_HOST?.includes('stage') || startedInsideCabinet;

const isHeadlessChrome = () => {
  const userAgent = navigator.userAgent;

  return userAgent.includes('HeadlessChrome');
}

export const initLogRocket = () => {
  const LogRocketKey = '8lugdu/gil';

  if (logrocketOff || isHeadlessChrome()) {
    console.log('initLogRocket ignored');
    return;
  }

  // Init Session
  LogRocket.init(LogRocketKey, {
    network: {
      requestSanitizer: request => {
        //@ts-ignore
        if (request?.headers?.Authorization) {
          //@ts-ignore
          request.headers.Authorization = '';
        }

        // ignore the request response pair
        //@ts-ignore
        if (request?.url?.toLowerCase().includes('login') || request?.url?.toLowerCase().includes('registration')) {
          return null;
        }

        return request;
      },
    },
  });
};
//@ts-ignore
export const identifyLogRocket = (user?: UserDTO | null)  => {

  if (logrocketOff) {
    console.log('identifyLogRocket ignored');
    return;
  }

  const fpPromise = FingerprintJS.load({ apiKey: 'tGEu49o2XgSPsanEtcPD' })

  // Get the visitor identifier when you need it.
  fpPromise
    .then((fp: { get: () => any; }) => fp.get())
    .then((result: { visitorId: any; }) => {
      // result.visitorId
      const fpId = result.visitorId;

      if (!user) {
        const loginEmail = 'unspecified';

        LogRocket.identify(fpId, {
          name: fpId,
          email: loginEmail,
          fpId,
        });
      } else {
        const loginEmail = user?.email || 'unspecified with user data';

        LogRocket.identify(loginEmail, {
          name: user?.name,
          email: loginEmail,
          id: user?.id,
          fpId: fpId,
        });
      }
    })

};
