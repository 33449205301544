import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FCC } from 'types';
import { useNavigate, useParams } from 'react-router-dom';

import _ from 'lodash';

import { getOrganizationsApi } from 'api/organizations';
import useApi from 'api/useApi';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
import {organizationInnerRoute, OrganizationDTO} from 'components/types';

type OrganizationContextType = {
  organization: OrganizationDTO | null;
  organizationId: OrganizationDTO['id'];
  organizations: OrganizationDTO[];
  organizationsOptions: { label: string; value: number | string }[];
  setOrganization: (value: OrganizationDTO) => void;
  isLoading: boolean;
  loadOrganizations: () => void;
  navigateToOrganizationPage: (path?: organizationInnerRoute) => void;
};

const OrganizationContextInitialState = {
  organization: null,
  organizationId: 0,
  organizations: [],
  organizationsOptions: [],
  setOrganization: _.noop,
  isLoading: false,
  loadOrganizations: _.noop,
  navigateToOrganizationPage: _.noop,
  timezone: '',
};

const OrganizationContext = React.createContext<OrganizationContextType>(
  OrganizationContextInitialState
);

const OrganizationProvider: FCC = ({ children }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<OrganizationDTO | null>(
    null
  );
  const [organizations, setOrganizations] = useState<OrganizationDTO[]>([]);
  const [organizationsOptions, setOrganizationsOptions] = useState<
    { label: string; value: number | string }[]
  >([]);

  const { data: organizationsDB, isLoading, forceRequest } = useApi<
    OrganizationDTO[]
  >(getOrganizationsApi);

  useEffect(() => {
    if (organizationsDB) {
      findOrganizationByUrlOrganizationId(organizationsDB)
      setOrganizations(organizationsDB);
      setOrganizationsOptions(
        organizationsDB.map((item) => ({
          value: item.id,
          label: `${item.name} (${item.address})`,
        }))
      );
    }
  }, [organizationsDB]);

  const findOrganizationByUrlOrganizationId = (organizations: OrganizationDTO[]) => {
    const organizationId = params?.organizationId;
    if(organizationId) {
      const org = organizations.find(organization => organization.id === Number(organizationId));
      setOrganization(org || null);
    }
  }
  useEffect(() => {
    findOrganizationByUrlOrganizationId(organizations);
  }, [params, organizations])


  const navigateToOrganizationPage = (path?: organizationInnerRoute) => {
    if(path) {
      navigate(`/organization/${organization!.id}/${path}`);
    } else {
      navigate(`/organization/${organization!.id}`)
    }

  }
  const value = useMemo(
    () => ({
      organization,
      organizationId: organization?.id as number,
      organizations,
      organizationsOptions,
      setOrganization,
      isLoading,
      loadOrganizations: forceRequest,
      navigateToOrganizationPage,
    }),
    [
      organization,
      organizations,
      organizationsOptions,
      setOrganization,
      isLoading,
      forceRequest,
      navigateToOrganizationPage,
    ]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {isLoading || !organization ? (
        <SpinnerPage className="OrganizationProvider_provide" />
      ) : (
        children
      )}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);

export default OrganizationProvider;
