import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {useOrganization} from 'providers/OrganizationProvider';
import {OrderDataExtended} from 'components/types';
import {OrderingKey} from 'types/order';
import {getOrderBEKey} from 'components/pages/UserOrdersPage/utils';
import {apiGetOrders} from 'api/order';
import {useAuth} from 'providers/AuthProvider';

type UserOrdersContextType = {
  doneOrdersState: ReturnType<typeof useOrderStatusData>;
  newOrdersState: ReturnType<typeof useOrderStatusData>;
  currentOrdersState: ReturnType<typeof useOrderStatusData>;
  currentStatus: 'new' | 'done';
  setCurrentStatus: (status: 'new' | 'done') => void;
  isLoading: boolean;
  noData: boolean;
};

const UserOrdersContext = createContext<UserOrdersContextType | undefined>(undefined);


type Props = PropsWithChildren;


type Args = {
  status: 'done' | 'new';
  defaultSortOrder?: OrderingKey;
}

const useOrderStatusData = ({status, defaultSortOrder = 'asc'}: Args) => {
  const { organizationId } = useOrganization();
  const [orders, setOrders] = useState<OrderDataExtended[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<OrderingKey>(defaultSortOrder);
  // const loadOnFirstRender = useRef(false)

  const _loadOrders = useCallback((sortOrderArg: OrderingKey = sortOrder ) => {
    setIsLoading(true);
    setSortOrder(sortOrderArg);
    apiGetOrders(organizationId, {status: getOrderBEKey(status), sortOrder: sortOrderArg})
      .then((result) => setOrders(result.data))
      .finally(() => setIsLoading(false));
  }, []);

  const changeSortOrder = (sortOrderArg: OrderingKey) => {
    if(sortOrderArg === sortOrder) return;
    setSortOrder(sortOrderArg);
    _loadOrders(sortOrderArg);
  }

  return {
    orders,
    setOrders,
    sortOrder,
    setSortOrder: changeSortOrder,
    isLoading,
    load: _loadOrders,
  }
}

const UserOrdersProvider = ({ children }: Props) => {
  const {user} = useAuth();
  const [currentStatus, setCurrentStatus] = useState<'new' | 'done'>('new');
  const doneOrdersState = useOrderStatusData({status: 'done'});
  const newOrdersState = useOrderStatusData({status: 'new', defaultSortOrder: 'desc'});

  const allStatusesLoading = doneOrdersState.isLoading || newOrdersState.isLoading;

  const value = {
    doneOrdersState,
    newOrdersState,
    currentOrdersState: currentStatus === 'new' ? newOrdersState : doneOrdersState,
    currentStatus,
    setCurrentStatus,
    isLoading: allStatusesLoading,
    noData: !allStatusesLoading && (doneOrdersState.orders.length === 0 && newOrdersState.orders.length === 0),
  }

  useEffect(() => {
    if(!user) return;
    doneOrdersState.load();
    newOrdersState.load();
  }, [user]);

  return (
    <UserOrdersContext.Provider value={value}>
      {children}
    </UserOrdersContext.Provider>
  )

}

export const useUserOrders = () => {
  const context = useContext(UserOrdersContext);
  if (context === undefined) {
    throw new Error('useUserOrders must be used within a UserOrdersProvider');
  }
  return context;
}

export default UserOrdersProvider;