import React, {FC, useMemo} from 'react';
import { OrganizationDTO } from '../../types';
import { Link } from 'react-router-dom';
import styles from './OrganizationListItem.module.scss';
import OrganizationGallery, {
  GalleryItem
} from 'components/pages/OrganizationListItem/OrganizationGallery/OrganizationGallery';

export interface IOrganizationListItem extends OrganizationDTO {

}


const OrganizationListItem: FC<IOrganizationListItem> = ({ id, name, address, gallery, image }) => {

  const galleryItemsOrigin = useMemo(() => gallery && JSON.parse(gallery), [gallery]);

  //@ts-ignore
  const galleryItems: GalleryItem[] = useMemo(() => galleryItemsOrigin && galleryItemsOrigin.reduce((acc, item) => {
    if(item.type === 'image' && item.data) {
      return [...acc, item]
    }

    //@ts-ignore
    if(item.type === 'map' && item?.data?.coordinates || item?.data?.address) {
      return [...acc, item]
    }

    return acc;
  }, []), [galleryItemsOrigin]);

  return (
    <div className={styles.component}>
      <Link to={`/organization/${id}`} className={styles.link}>
        <div>
          <div className={styles.name}>{name}</div>
          {address && (
            <div className={styles.address}>{address}</div>
          )}
        </div>
        {/*{address && <MapEmbed mapAddress={address} className={styles.mapEmbed}/>}*/}

        {galleryItems && (
          <OrganizationGallery items={galleryItems} />
        )}

      </Link>
    </div>

  )
}

export default OrganizationListItem;
