import {ServicesDTO} from 'components/types';

export const isSingleService = (services: ServicesDTO) => {
  if(services.length === 1) {
    if(services?.[0].type === 'category' && services?.[0].items.length === 1) {
      return true;
    }
    if(services?.[0].type === 'item') {
      return true;
    }
  }
  return false;
}