import React from 'react';
import { FCC } from 'types';
import cn from 'classnames';

import styles from './TimeSlotItem.module.scss'


export interface ITimeSlotItem {
    onClick?: () => void;
    className?: string;
}

const TimeSlotItem: FCC<ITimeSlotItem> = ({onClick, className, children }) => {
    return (
        <div className={cn(styles.component, className)} onClick={onClick}>
            {children}
        </div>
    )
}

export default TimeSlotItem;