import React, {ChangeEvent} from 'react';
import { FCC } from 'types';
import cn from 'classnames';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { i18nt } from "translations/i18n";

import styles from './InputSearch.module.scss'

export interface InputSearchInterface {
    value?: string;
    autoFocus?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
    className?: string;
    onClear?: () => void;
    placeholder?: string;
}

const InputSearch: FCC<InputSearchInterface> = ({ value= '', autoFocus = false, onChange, onClear, placeholder, className }) => {
    // const { t } = useTranslation();
    //
    // const placeholderValue = t('Login to account');
    // console.log({placeholderValue});
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e, e.target.value)
    }

    return (
        <div className={cn(styles.component, className)}>
            <SearchIcon />
            <input
                value={value}
                className={styles.input}
                placeholder={placeholder || i18nt('SEARCH')}
                autoFocus={autoFocus}
                onChange={handleChange}
            />
            {value.length > 0 && (
                <HighlightOffIcon onClick={onClear} className={styles.clearIcon}/>
            )}

        </div>
    )
}

export default InputSearch;
// export default InputSearch;
