import React, {ChangeEvent, useCallback, useState} from 'react';
import { FCC } from 'types';
import _ from "lodash";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import {FormControl, FormHelperText} from "@mui/material";

import { useOrganization } from 'providers/OrganizationProvider';
import PhoneInput from 'components/Input/PhoneInput/PhoneInput';
import { useAuth } from 'providers/AuthProvider';
import { i18nt } from 'translations/i18n';
import { globalLocale } from 'utils/dateUtil';
import Button from 'components/Button/Button';
import ClassNameWrapper from 'components/ClassNameWrapper/ClassNameWrapper';
import TextArea from 'components/TextArea/TextArea';
import {PRIVACY_POLICY_URL, TERMS_OF_USE_URL} from 'appInfo';
import {validateEmail} from 'utils/utils';

import OrderPageHeader from "../OrderPageHeader/OrderPageHeader";
import {useStoreProviderContext} from "providers/StoreProvider/StoreProvider";
import {ContactsDataType} from "../types";
import ReminderSelect, {DEFAULT_REMINDER_VALUE} from "./ReminderSelect";
import Checkbox from "../Checkbox/Checkbox";
import Content from "../Content/Content";

import styles from './ContactsForm.module.scss'


const DEFAULT_ERROR_TEXT = i18nt('PLEASE_FILL_IN_THE_FIELD');

const ContactsForm: FCC = () => {
    const { navigateToOrganizationPage } = useOrganization();
    const { submitUserOrder } = useStoreProviderContext();
    const {user} = useAuth();

    const [data, setData] = useState<ContactsDataType>({
        phone: user?.phone || '',
        name: user?.name ||'',
        email: user?.email ||'',
        comment: '',
        readDocs: false,
        reminderValue: DEFAULT_REMINDER_VALUE,
    });

    const checkForFillmentData = () => {
        const { comment, ...requiredData } = data;
        return Object.values(requiredData).every(item => {
            if(typeof item == "boolean" && item) return true;
            if(typeof item == "number" && item) return true;
            return !_.isEmpty(item)
        });
    }
    const readyToSubmit = checkForFillmentData();

    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const validateFields = () => {
        const errors: {[key: string]: string} = {};
        if(data.name.length < 2) errors['name'] = DEFAULT_ERROR_TEXT;
        if(data.email.length < 1) errors['email'] = DEFAULT_ERROR_TEXT;

        if(!validateEmail(data.email).valid) errors['email'] = i18nt('EMAIL_IS_NOT_VALID');

        if(data.phone.length < 1) errors['phone'] = DEFAULT_ERROR_TEXT;
        if(!data.readDocs) errors['readDocs'] = DEFAULT_ERROR_TEXT;

        setErrors(errors)
        return Object.keys(errors).length === 0;
    }
    const isValid = (key: keyof ContactsDataType) => errors[key] || '';

    const handleChangeCore = useCallback((name: keyof ContactsDataType, value: any) => {
        const modifiedErrors = {...errors};
        delete modifiedErrors[name];

        setErrors(modifiedErrors);
        setData(prevState => ({...prevState, [name]: value }))
    }, [errors])

    const handleChangeTextField = useCallback((name: keyof ContactsDataType) => (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        handleChangeCore(name, e.target.value)
    }, [errors])

    const handleSubmit = () => {
        const isValid = validateFields();

        if(isValid) {
            submitUserOrder({...data, locale: globalLocale.key})
        }

    }

    return (
      <ClassNameWrapper elementClassName="Layout_component" className={styles.layout}>
        <div className={styles.component}>
            <OrderPageHeader backButton={{
                onClick: navigateToOrganizationPage
            }}/>
            <Content>
            <div className={styles.content}>

                <TextField
                    label={i18nt('NAME')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    value={data.name}
                    onChange={handleChangeTextField('name')}
                    className={styles.input}
                    error={!!errors.name}
                    helperText={isValid('name')}
                />
                <TextField
                    label="Email"
                    type="email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    value={data.email}
                    onChange={handleChangeTextField('email')}
                    className={styles.input}
                    error={!!errors.email}
                    helperText={isValid('email')}
                />
                <PhoneInput
                  country={'ua'}
                  value={data.phone}
                  onChange={(phone: string) => handleChangeCore('phone', phone )}
                  error={!!errors.phone}
                  helperText={isValid('phone')}
                  className={styles.input}
                />
                <div className={styles.reminderBlock}>
                    <div className={styles.reminderTitle}>
                        {i18nt('REMINDER')}
                    </div>
                    <ReminderSelect
                        className={styles.reminderSelect}
                        value={data.reminderValue}
                        onChange={(value) => handleChangeCore('reminderValue', value)}
                    />
                </div>
                <TextArea
                    label={i18nt('COMMENT')}
                    value={data.comment}
                    onChange={handleChangeTextField('comment')}
                    className={styles.input}
                />

                <div className={styles.footer}>
                    <FormControl
                      required
                      error={!!errors.readDocs}
                      component="fieldset"
                      sx={{m: 3}}
                      variant="standard"
                    >
                        <FormControlLabel
                          label={(<div className={styles.textTermsOfUse}>{i18nt('BY_CLICKING_MAKE_A_VISIT_YOU_ACCEPT')} <a href={TERMS_OF_USE_URL}
                                                                                         target="_blank">{i18nt('TERMS_OF_USE_RODOVIJ')}</a> {i18nt('AND')} <a href={PRIVACY_POLICY_URL} target="_blank">{i18nt('PRIVACY_POLICY_RODOVIJ')}</a></div>)}
                          control={
                              <Checkbox checked={data.readDocs}
                                        onChange={(e, value) => handleChangeCore('readDocs', value)}/>
                          }
                        />
                        {errors?.readDocs && (
                          <FormHelperText>{isValid('readDocs')}</FormHelperText>
                        )}

                    </FormControl>

                        {/*<div className={styles.textTermsOfUse}>*/}
                        {/*    {i18nt('BY_CLICKING_MAKE_A_VISIT_YOU_ACCEPT')} <a href={TERMS_OF_USE_URL} target="_blank">{i18nt('TERMS_OF_USE')}</a> {i18nt('AND')} <a href={PRIVACY_POLICY_URL} target="_blank">{i18nt('PRIVACY_POLICY')}</a>*/}
                        {/*</div>*/}
                    <div onClick={handleSubmit}>
                        <Button variant="primary" className={styles.submitButton}
                                disabled={!readyToSubmit}>{i18nt('MAKE_A_VISIT')}</Button>
                    </div>
                </div>
            </div>
            </Content>
        </div>
      </ClassNameWrapper>
    )
}

export default ContactsForm;