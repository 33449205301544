import React from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'
import { i18nt } from 'translations/i18n';

// import { ReactComponent as ErrorIcon } from 'assets/icons/notificationError.svg'
// import {ToastOptions} from "react-toastify/dist/types";

export type ShowNotificationType = {
    type: 'success' | 'info' | 'error' | 'warning' | 'warn';
    content?: ToastContent;
    options?: ToastOptions;
}

const defaultMessages = {
    success: i18nt('EVERYTHING_IS_GREAT'),
    info: '',
    error: i18nt('AN_ERROR_OCCURRED'),
    warning: i18nt('ERROR_CONTACT_THE_ADMINISTRATOR'),
    warn: i18nt('ERROR_CONTACT_THE_ADMINISTRATOR'),
}

const showNotification = ({ type, content, options }: ShowNotificationType) : React.ReactText => {
    const message = content || defaultMessages[type];

    return toast[type](message, {...options})
}

export default showNotification
