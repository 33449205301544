
const preloadImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    function rendered() {
      //Render complete
      resolve(img);
      img.remove();
    }
    function startRender() {
      //Rendering start
      requestAnimationFrame(rendered);
    }

    img.onload = () => {
      requestAnimationFrame(startRender);
    };

    img.onerror = () => {
      reject(new Error(`Failed to load image from ${url}`));
    };

    img.src = url;
    img.style.position = 'absolute';
    img.style.top = '-99999px';
    img.style.left = '-99999px';
    img.style.width = '100%';
    img.style.height = '100%';

    document.body.appendChild(img);
  });
}

export default preloadImage;
