import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './en.json';
import translationFR from './fr.json';
import translationUA from './ua.json';
import translationHR from './hr.json';

// import translationRU from './ru.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ua: {
        translation: translationUA
    },
    uk: {
      translation: translationUA
    },
    fr: {
        translation: translationFR
    },
    hr: {
      translation: translationHR
    }
};

i18n
    .use(detector)
    // .use(languageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "en",
        fallbackLng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            // bindI18n: 'languageChanged',
            // bindI18nStore: '',
            // transEmptyNodeValue: '',
            // transSupportBasicHtmlNodes: true,
            // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            // useSuspense: true,
        }
    });

// const i18nt = i18n;

export type TranslationsType = {
  [key in keyof typeof translationUA]: string;
};


export const i18nt = i18n.t.bind(i18n) as (value: (keyof TranslationsType), options?: Record<string, string | number>) => string;
// console.log(i18n);
// console.log(i18n.t('Users'));
// console.log(i18nn('Users'));
// console.log(i18nt);

//@ts-ignore
window.i18n = i18n;

export default i18n;