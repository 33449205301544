import React, { FC } from 'react';

import cn from 'classnames';

import Spinner from 'components/Spinner/Spinner';

import styles from './SpinnerPage.module.scss';

interface SpinnerPageProps {
  className?: string;
}

const SpinnerPage: FC<SpinnerPageProps> = ({ className }) => (
  <Spinner className={cn(styles.component, className)} />
);

export default SpinnerPage;
