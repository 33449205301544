import React, {FC} from 'react';
import cn from 'classnames';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import styles from './HeaderArrowNavigation.module.scss'
import { FCC } from 'types';

export interface IHeaderArrow {
    disabled?: boolean;
    onClick?: () => void;
    className?: string
}

export interface IHeaderArrowNavigation {
    className?: string;
    prevButton?: IHeaderArrow;
    nextButton?: IHeaderArrow;
}

const HeaderArrowNavigation: FCC<IHeaderArrowNavigation> = ({prevButton, nextButton, children}) => {
    return (
        <div className={cn(styles.component)}>
            <div>
                {prevButton && (
                    <IconButton className={cn(styles.button, prevButton?.className)} size="large" onClick={prevButton?.onClick} disabled={prevButton?.disabled}>
                        <KeyboardArrowLeftIcon fontSize="inherit" />
                    </IconButton>
                )}

            </div>

            <div className={styles.content}>
                {children}
            </div>

            <div>
                {nextButton && (
                    <IconButton className={cn(styles.button, prevButton?.className)} size="large" onClick={nextButton?.onClick} disabled={nextButton?.disabled}>
                        <KeyboardArrowRight fontSize="inherit" />
                    </IconButton>
                )}
            </div>

        </div>
    )
}

export default HeaderArrowNavigation;