import React, { FC } from 'react';
import cn from 'classnames';
// import * as muiIcons from '@mui/icons-material';

import icons, { IconsMapType } from './../../assets/icons/icons';

import styles from './Icon.module.scss';
import { FCC } from 'types';

export interface IconProps{
  name: keyof IconsMapType;
  className?: string
  title?: string;
  onClick?: () => void;
}

const Icon: FCC<IconProps> = ({ name, className, ...props }) => {
  const IconComponent = icons[name]

  // console.log({name, IconComponent, icons})
  if(!IconComponent) return null;

  return <IconComponent className={cn('Icon', styles.component, className)} {...props}/>;
};

export default Icon;
