import React, { useEffect, useContext, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import _ from 'lodash';

import {apiGetUserData} from 'api/auth';
import {setStorageToken} from 'utils/user';
import {identifyLogRocket} from 'utils/logrocketUtil';
// import {useLocation, useNavigate} from "react-router-dom";
// import {apiGetUser, apiUserLogin} from "./auth";

export type UserDTO = {
    email: string,
    apiToken: string;
    id: number;
    name: string;
    phone: string;
}


type AuthContextType = {
    token: string | null;
    onLogout: () => void;
    user: UserDTO | null;
    setUser: (user: UserDTO) => void;
    userIsLoading: boolean;
}
const AuthContextInitialState = {
    token: null,
    onLogout: _.noop,
    user: null,
    setUser: _.noop,
    userIsLoading: false,
}
const AuthContext = React.createContext<AuthContextType>(AuthContextInitialState);

type Props = {
    children?: React.ReactNode
};

const AuthProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const inviteToken = searchParams.get('init_token') as string;

    if(inviteToken) {
        localStorage.setItem('token', inviteToken);
        searchParams.delete('init_token');

        navigate({ search: searchParams.toString() });
    }
    // const isAuthPage = location.pathname === '/login' || location.pathname === '/registration'

    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [user, setUser] = useState<UserDTO | null>(null);
    const [userIsLoading, setUserIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if(token) {
            setUserIsLoading(true);
            apiGetUserData().then((result) => {
                handleUser({
                    ...result?.data,
                    apiToken: token,
                });
                setUserIsLoading(false);
            }).catch((error) => {
                console.log('error getUser', error)
                handleLogout();
                setUserIsLoading(false);
            })

            return;
        }
    }, [])

    useEffect(() => {
        if(token && !user) return;
        //@ts-ignore
        identifyLogRocket(user);
    }, [token, user]);

    const handleLogout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem('token');
        // navigate('/login');
    };

    const handleUser = (user: UserDTO) => {
        setStorageToken(user.apiToken);
        setToken(user.apiToken);
        setUser(user);
    }
    const value = {
        token,
        onLogout: handleLogout,
        user,
        setUser: handleUser,
        userIsLoading,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;