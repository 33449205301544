import React from 'react';
import { FCC } from 'types';
import cn from 'classnames';

import styles from './Content.module.scss';
interface IContent {
    className?: string;
}

const Content: FCC<IContent> = ({className,children}) => (
    <div className={cn(styles.component, className)}>
        <div className={styles.content}>
            {children}
        </div>
    </div>
)

export default Content;