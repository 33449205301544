import React from 'react';
import { useParams } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";
import Icon from 'components/Icon/Icon';
import { useOrganization } from 'providers/OrganizationProvider';
import { i18nt } from 'translations/i18n';

import styles from './LayoutFooter.module.scss';
import cn from 'classnames';

const LayoutFooter = () => {
    const { shortOptions } = useStoreProviderContext();
    const {navigateToOrganizationPage} = useOrganization();

    const params = useParams();

    return (
        <BottomNavigation
            showLabels
            value={params?.['*'] || null}
            onChange={(event, newValue) => {
              navigateToOrganizationPage(newValue);
            }}
            className={styles.component}
        >
            {!shortOptions && <BottomNavigationAction label={i18nt('ABOUT_US')} value={'about'} icon={<Icon name="infoRound" className={styles.icon} />} />}
            <BottomNavigationAction label={i18nt('CREATE_ORDER')} value={null} icon={<Icon name="plusCircledSolid" className={cn(styles.icon, styles.signUp)} />} />
            <BottomNavigationAction label={i18nt('MY_APPOINTMENTS')} value={'orders'}  icon={<Icon name="orders" className={styles.icon} />} />
        </BottomNavigation>
    )
}

export default LayoutFooter;