import React, {FC} from "react";
import { useTranslation } from 'react-i18next';
import cn from "classnames";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import * as yup from "yup";

import Input from "components/Input/Input";
import Button from "components/Button/Button";

import styles from "./LoginRegistrationForm.module.scss";
import PhoneInput from 'components/Input/PhoneInput/PhoneInput';
import TEXTS from 'constants/texts';
import { FCC } from 'types';

const schema = yup.object({
    email: yup.string().email(TEXTS.REQUIRED_FIELD).required(TEXTS.REQUIRED_FIELD),
    name: yup.string().required(TEXTS.REQUIRED_FIELD),
    phone: yup.string().required(TEXTS.REQUIRED_FIELD),

}).required();

export interface LoginRegistrationFormDataProps { email: string, name: string, phone: string }

export interface LoginRegistrationFormProps {
    email?: string
    onSubmit: (value: LoginRegistrationFormDataProps) => void;
    className?: string;
}

const LoginRegistrationForm: FCC<LoginRegistrationFormProps> = ({ email, onSubmit, className }) => {
  const { t } = useTranslation();
    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: email || '',
            name: '',
            phone: '',
        },
    });

    return (
        <div className={cn(styles.component, className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Input
                        label="Email"
                        error={!!errors?.email?.message}
                        helperText={errors?.email?.message}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Input
                        label={t('NAME')}
                        error={!!errors?.name?.message}
                        helperText={errors?.name?.message}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    country={'ua'}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.phone?.message}
                    helperText={errors?.phone?.message}
                    className={styles.inputPhone}
                  />
                )}
              />
                <Button
                    variant="primary"
                    type="submit"
                >
                  {t('CONTINUE')}
                </Button>
        </form>
        </div>

    )
}

export default LoginRegistrationForm