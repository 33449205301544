import React from 'react';

import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import useEmployeeData from 'hooks/useEmployeeData';
import Spinner from 'components/Spinner/Spinner';
import EmployeeFeedbacks from 'components/pages/EmployeePage/EmployeeFeedbacks/EmployeeFeedbacks';
import SpecialistPreviewBase from 'components/SpecialistPreview/SpecialistPreviewBase/SpecialistPreviewBase';

import styles from './EmployeePage.module.scss';

const EmployeePage = () => {
  // const [selectedTab, setSelectedTab] = useState(0);
  const { employeeInformation, isLoading } = useEmployeeData();
  // const

  if(!employeeInformation || !employeeInformation?.id) return null;

  return (
    <div className={styles.component}>
      <OrderPageHeader defaultBackButton title="Cторінка спеціаліста"/>
      <div className={styles.content}>
        {isLoading ? <Spinner/> : (
          <>
            <SpecialistPreviewBase
              specialist={employeeInformation}
              showAboutButton={false}
              avatarImageClassName={styles.avatar}
              className={styles.preview}
              showFeedbackButton
            />
            <EmployeeFeedbacks feedbacks={employeeInformation.feedbacks}/>
            {/*Left maybe tabs will be used in future 18.11.2023*/}
            {/*<Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>*/}
            {/*  <Tab label="Відгуки" value={0}/>*/}
            {/*  <Tab label="Послуги" value={1}/>*/}
            {/*</Tabs>*/}
          </>
        )}

      </div>
    </div>
  )
}

export default EmployeePage;
