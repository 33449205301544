import React from 'react';
import {useNavigate} from 'react-router-dom';

import {i18nt} from 'translations/i18n';
import Icon from 'components/Icon/Icon';
import {useAuth} from 'providers/AuthProvider';
import {useOrganization} from 'providers/OrganizationProvider';

import styles from './UserHelloBlock.module.scss';
import Button from 'components/Button/Button';

const UserHelloBlock = () => {
  const navigate = useNavigate();
  const { user, onLogout } = useAuth();
  const { organization } = useOrganization();

  const handleLogout = () => {
    onLogout();

    navigate(`/organization/${organization!.id}`);
  }

  if(!user) return null;

  return (<div className={styles.component}>
    <div className={styles.name}>
      {i18nt('HELLO')},
      <span className={styles.userName}>{user.name}</span>
      !
    </div>
    <div className={styles.buttons}>
      {/*TODO: Need to add settings page*/}
      {/*<button className={styles.button}>*/}
      {/*  <Icon name="settings"/>*/}
      {/*  Налаштування*/}
      {/*</button>*/}
      <Button variant="secondary" startIcon="exitDoor" onClick={handleLogout}>
        {i18nt('LOGOUT')}
      </Button>
    </div>
  </div>);

};

export default UserHelloBlock;