import React from 'react';
import cn from 'classnames';
import Rating from '@mui/material/Rating';

import {FeedbackExtended} from 'components/types';
import {dateFormat} from 'utils/dateUtil';

import styles from './FeedbackListItem.module.scss';

type Props = {
  className?: string;
  feedback: FeedbackExtended;
}
const FeedbackListItem = ({className, feedback}: Props) => (
  <div className={cn(styles.component, className)}>
    <div className={styles.header}>
      <div className={styles.user}>
        {feedback.name}
      </div>
      <div className={styles.rightBar}>
        <div className={styles.date}>{`${dateFormat(new Date(feedback.createdAt))}`}</div>
        {/*{formatRelative(new Date(feedback.createdAt), new Date())}*/}
        <Rating value={feedback.mark} className={styles.mark} readOnly/>
      </div>

    </div>
    <div className={styles.content}>
      {feedback.text}
    </div>
  </div>

)

export default FeedbackListItem;