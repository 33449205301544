import {useEffect, useState} from 'react';

import {
  CredentialResponse,
  useGoogleOneTapLogin,
  GoogleLogin,
  // useGoogleLogin,
  // PromptMomentNotification
} from '@react-oauth/google';

import {globalLocale} from 'utils/dateUtil';
import {apiAuthByGoogle} from 'api/auth';
import {SocialAuthCallback} from 'components/AuthForm/SocialAuthPage/types';
import cookies from 'utils/cookies';

type Props = {
  callback: SocialAuthCallback;
  setIsLoading: (isLoading: boolean) => void;
  // isLoading?: boolean;
}
const SocialAuthGoogle = ({callback, setIsLoading}: Props) => {
  const [hideButton, setHideButton] = useState(false);

  const errorCallback= (error?: any) => {
    console.log('error');
    callback({error});
    setHideButton(false);
    setIsLoading(false);
  }
  const handleSuccessGoogle= (response: CredentialResponse) => {
    // console.log('response', response)
    setIsLoading(true);
    apiAuthByGoogle({...response, locale: globalLocale.key })
      .then((response) => {
        callback({user: response.data});
      })
      .catch((e) => {
        callback({error: e});
      })
  }

  // const login = useGoogleLogin({
  //   onSuccess: codeResponse => console.log(codeResponse),
  //   onNonOAuthError:errorCallback,
  // });
  useGoogleOneTapLogin({
    onError: errorCallback,
    onSuccess: handleSuccessGoogle,
    promptMomentNotification: (data) => {
      console.log('promptMomentNotification', data);
      // debugger;
      //@ts-ignore
      if(data.h === false || data.g === 'skipped') {
        setIsLoading(false);
        setHideButton(false);
      }
    },
  })

  useEffect(() => {
    cookies.delete('g_state');
    setHideButton(true);
    setIsLoading(true);
    // login();
  }, []);

  if(!hideButton) {
    return (
      <GoogleLogin
        onSuccess={handleSuccessGoogle}
        // onError={errorCallback}
        onError={() => {
          console.log('@@@@')
        }}
        // click_listener={() => {
        //          console.log('click_listener');
        //        }}
        // useOneTap
      />
    );
  }
  // return (
  //   <GoogleLogin
  //     onSuccess={handleSuccessGoogle}
  //     // onError={errorCallback}
  //     onError={() => {
  //       console.log('@@@@')
  //     }}
  //     click_listener={() => {
  //              console.log('click_listener');
  //            }}
  //     useOneTap
  //   />
  // );
  // return (
  //   <div style={{display: (hideButton || isLoading) ? 'none' : 'block'}}>
  //     <GoogleLogin
  //       onSuccess={handleSuccessGoogle}
  //       // onError={errorCallback}
  //       onError={() => {
  //         console.log('@@@@')
  //       }}
  //       nonce={new Date().getTime().toString()}
  //       promptMomentNotification={(data: PromptMomentNotification) => {
  //         console.log('qqqq', data);
  //       }}
  //       // click_listener={() => {
  //       //   console.log('click_listener');
  //       // }}
  //
  //
  //       // state_cookie_domain="localhost:3002"
  //       // auto_select
  //       // useOneTap
  //       // onNonOAuthError={(asd) => {
  //       //   console.log('asd', asd);
  //       // }}
  //     />
  //   </div>
  // );

  return <></>;
};

export default SocialAuthGoogle;
