import {useEffect} from 'react';
import api from 'api/api';


type ManifestJSON = {
  short_name: string;
  name: string;
  icons?: {
    src: string;
    type: string;
    sizes: string;
  }[];
  // start_url: string;
  // display: string;
  // background_color?: string;
  // theme_color?: string;
}
const getFileFormat = (path: string) => {
  if(!path) return null;
  const splittedByDotPath = path.split('.');
  const fileTypeShort = splittedByDotPath[splittedByDotPath.length - 1];

  if(fileTypeShort === 'svg') {
    return 'image/svg+xml'
  } else if (['png', 'jpg', 'jpeg'].includes(fileTypeShort)) {
    return `image/${fileTypeShort}`
  }

  return null;
}

type createJSONManifestDataProps = {
  name: string;
  shortName: string;
  iconURL: string;
}

const createJSONManifestData = ({name, shortName, iconURL}: createJSONManifestDataProps): ManifestJSON => {

  const basicManifest = {
    name,
    'short_name': name || shortName,
    'start_url': '/',
    'display': 'standalone',
  }
  if(iconURL) {
    //@ts-ignore
    basicManifest.icons = [{
      src: iconURL,
      type: getFileFormat(iconURL),
      sizes: '72x72 96x96 120x120 128x128 180x180 256x256'
    }]
  }

  return basicManifest;
}

const replaceDOMManifest = (manifestJSON: ManifestJSON) => {
  const stringManifest = JSON.stringify(manifestJSON)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob);

  //@ts-ignore
  document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL)
}

const useCustomInformation = () => {

  useEffect(() => {
    api.get(`/company`)
      .then((result) => {
        const data = result.data;
        // const startUrl = `https://${data.domain}/${ENV_DOMAIN}`;

        const manifestData = {
          name: data?.appName || data?.name as string,
          shortName: data?.appName || data?.name as string,
          iconURL: data.image as string,
        }
        const manifestJSON = createJSONManifestData(manifestData)

        //@ts-ignore
        document.querySelector('title').innerHTML = manifestData.name;
        // // document.querySelector('link[rel="shortcut icon"]').setAttribute('href', `${appConfig.publicUrl}/favicon.ico`);
        if(manifestData.iconURL) {
          //@ts-ignore
          document.querySelector('link[rel="apple-touch-icon"]').setAttribute('href', manifestData.iconURL || '/favicon.ico');
          //@ts-ignore
          document.querySelector('link[rel="icon"]').setAttribute('href', manifestData.iconURL || '/favicon.ico');
        }

        replaceDOMManifest(manifestJSON)
      })
      .catch(() => {
        console.log('error');
      })
  }, [])

};

export default useCustomInformation;
