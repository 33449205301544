import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import OrderPageHeader from "components/OrderPageHeader/OrderPageHeader";
import Button from "components/Button/Button";
import {useStoreProviderContext} from "providers/StoreProvider/StoreProvider";
import { apiDeleteOrder } from 'api/order';
import showNotification from "components/Notifications/showNotification";
import { useOrganization } from 'providers/OrganizationProvider';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
import OrderNotExisted from 'components/OrderNotExisted/OrderNotExisted';
import { i18nt } from 'translations/i18n';
import useOrderByHash from 'hooks/useOrderByHash';
import ClassNameWrapper from 'components/ClassNameWrapper/ClassNameWrapper';
import SpecialistPreviewBase from 'components/SpecialistPreview/SpecialistPreviewBase/SpecialistPreviewBase';
import FeedbackButton from 'components/FeedbackButton/FeedbackButton';

import styles from 'components/pages/OrderDetailsPage/OrderDetailsPage.module.scss';

export type OrderDetailsPageProps = {
    standalonePage?: boolean;
}

const OrderDetailsPage: FC<OrderDetailsPageProps> = ({standalonePage}) => {
    const {successOrder, isLoaded, setSuccessOrder, isPastEvent, date, time, totalServices} = useOrderByHash();
    const location = useLocation();
    const navigate = useNavigate();
    const { navigateToOrganizationPage } = useOrganization();
    const { resetOrderListData, resetLoadedOptions } = useStoreProviderContext();

    if(isLoaded && !successOrder) {
        return (
          <div className={styles.component}>
              <OrderPageHeader defaultBackButton title={i18nt('ORDER_DETAILS')} backButton={{ onClick: navigateToOrganizationPage}}/>
              <div className={styles.content}>
                <OrderNotExisted />
              </div>
          </div>
            )
    }

    if(!successOrder) {
        return (
          <div className={styles.component}>
              <SpinnerPage />
          </div>
        )
    }

    const handleLeaveThisPage = () => {
        const pathname = location.pathname.split('/')
        pathname.pop();
        const defaultUrl = pathname.join('/')
        resetOrderListData();
        {standalonePage ? navigateToOrganizationPage() : navigate(defaultUrl)}
    }

    const handleDeleteOrder = () => {
        apiDeleteOrder(successOrder!.hash, successOrder!.organization!.id)
            .then(() => {
                showNotification({type: 'success', content: i18nt('THE_RECORD_WAS_SUCCESSFULLY_DELETED')})
                setSuccessOrder(undefined);
                handleLeaveThisPage();
                resetLoadedOptions();
            })
            .catch(() => {
                showNotification({type: 'error', content: i18nt('IT_WAS_NOT_POSSIBLE_TO_DELETE_THE_RECORD')})
            })
    }

    // const handleEditOrder = () => {
    //     navigate(`/organization/${successOrder.organization.id}/orders/${successOrder.hash}/edit`)
    // };

    return (
      <ClassNameWrapper elementClassName="Layout_component" isContent>
        <div className={styles.component}>
            <OrderPageHeader defaultBackButton title={i18nt('ORDER_DETAILS')} backButton={{ onClick: handleLeaveThisPage}}/>
            <div className={styles.content}>
                <p className={styles.sectionName}>{i18nt('ADDRESS')}:</p>
                <div>
                    <div className={styles.organizationAddress}>
                        {successOrder?.organization.address}
                    </div>
                    <div>
                        {successOrder?.organization?.phones?.map(phone => (
                            <p>{phone}</p>
                        ))}
                    </div>
                </div>
                <p className={styles.sectionName}>{i18nt('DATE_AND_TIME')}:</p>
                <div className={styles.dateAndTime}>
                    {`${date} ${time}`}
                </div>
                <p className={styles.sectionName}>{i18nt('SPECIALIST')}:</p>
                <SpecialistPreviewBase specialist={successOrder?.employee} showAboutButton={false} className={styles.specialistPreview}/>
                <p className={styles.sectionName}>{i18nt('SERVICES')}:</p>
                {successOrder?.services.map(service => (
                    <div className={styles.serviceItem}>
                        <div>
                            {service.name}
                        </div>
                        <div>
                            {service.price} {service.currency}
                        </div>
                    </div>
                ))}
                <div className={styles.serviceSummary}>
                  {i18nt('TOTAL')}: {totalServices}
                </div>
                {!isPastEvent && (
                  <>
                    <Button variant="primary" className={styles.button} onClick={handleDeleteOrder}>{i18nt('CANCEL_ORDER')}</Button>
                    {/*TODO: Need to handle onClick*/}
                    {/*<Button variant="text" className={styles.button} onClick={handleEditOrder}>{i18nt('EDIT_ORDER')}</Button>*/}
                  </>
                )}
                {isPastEvent && successOrder?.leaveFeedback && <FeedbackButton orderHash={successOrder.hash} className={styles.button}/>}
                <Button variant="text" className={styles.button} onClick={handleLeaveThisPage}>{standalonePage ? i18nt('TO_THE_LIST_OF_SERVICES') : i18nt('TO_THE_LIST_OF_RECORDS')}</Button>
            </div>
        </div>
      </ClassNameWrapper>
    )
}

export default OrderDetailsPage;
