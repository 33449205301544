import React, { useEffect, useState } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import queryString from 'query-string';

import 'react-datepicker/dist/react-datepicker.css';
import { add, format } from 'date-fns';
import { apiGetCalendarDaysList } from 'api/calendar';
import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";
import Spinner from '../Spinner/Spinner';
import { toSnakeCase } from 'utils/apiUppercase';
import { useOrganization } from 'providers/OrganizationProvider';
import i18n from 'i18next';
import { FCC } from 'types';
import {dateFormats} from 'utils/dateUtil';
import cn from 'classnames';

export interface IDatePicker {
  onChange: (date: Date | null) => void,
  date: Date | null;
  renderDateCustomHeader?: any;
}

const DatePicker: FCC<IDatePicker> = ({ date, onChange, renderDateCustomHeader }) => {
  const { organization } = useOrganization();
  const { specialist, services } = useStoreProviderContext();
  const [ startDate, setStartDate ] = useState<Date | null>(date || null);

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  // const [activeDays, setActiveDays] = useState<Date[]>([])
  const [ disabledDays, setDisabledDays ] = useState<Date[]>([]);

  useEffect(() => {
    if (startDate === date) return;
    onChange(startDate)
  }, [ startDate ])

  const highlightedDays = date ? [ date ] : [];

  useEffect(() => {
    setIsLoading(true)
    const localDate = new Date();

    const query = {}
    if (specialist?.id) {
      // @ts-ignore
      query['employeeId'] = specialist?.id;
    }

    if (services?.length && services?.length > 0) {
      // @ts-ignore
      query['servicesIds'] = services.map(item => item.id);
    }

    const allDays: string[] = [];

    for(let i = 0; i <= 180; i++) {
      allDays.push(format(add(localDate, {days: i}),'yyyy-MM-dd'))
    }

    apiGetCalendarDaysList({
      organizationId: organization!.id,
      query: queryString.stringify(toSnakeCase(query), { arrayFormat: 'comma' })
    })
      .then((result) => {

        //@ts-ignore
        const schedules = result.data;
        //@ts-ignore
        const disabledDaysStrings = allDays.filter(item => !schedules.bookingDate.includes(item))

        //@ts-ignore
        const disabledDays: Date[] = disabledDaysStrings.map(item => {
          const year = item.substr(0, 4);
          const month = item.substr(5, 2);
          const day = item.substr(8, item.length - 1);
          return new Date(`${year}-${month}-${day}`);
        })

        console.log({disabledDays});
        setDisabledDays(disabledDays)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [ date ])


  const maxData = add(new Date(), { days: 90 })


  const vv = disabledDays.map(item => format(item, 'yyyy-MM-dd'));



  return (
    <div className="DatePicker_component">
      {isLoading && <Spinner/>}
      {!isLoading && (
        <ReactDatePicker
          locale={i18n.language === 'ua' ? 'uk' : i18n.language}
          onChange={(date: Date) => setStartDate(date)}
          //@ts-ignore
          onDayClick={(a) => console.log(a)}
          inline
          minDate={new Date()}
          maxDate={maxData}
          highlightDates={highlightedDays}
          renderCustomHeader={renderDateCustomHeader}
          excludeDates={disabledDays}
          calendarStartDay={i18n.language === 'en' ? 0 : 1}
          renderDayContents={(dayOfMonth: number, date: Date) => {
              return (
                <div
                  className={cn('calendar_day', {
                    disabled: vv.includes(format(date, 'yyyy-MM-dd')),
                  })}
                  data-date={format(new Date(date), dateFormats.dateView)}
                  data-month={format(new Date(date), dateFormats.month)}
                >
                  {dayOfMonth}
                </div>
              )
          }}
        />
      )}
    </div>
  );
}

export default DatePicker;