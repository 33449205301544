import React from 'react';
import { FCC } from 'types';
import cn from "classnames";
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {SpecialistDetails, SpecialistNearestTimeslotType, TTimeSlot} from 'components/types';
import NearestTimeslots from 'components/NearestTimeslots/NearestTimeslots';
import {Link} from 'react-router-dom';

import styles from './SpecialistPreviewBase.module.scss';
import FeedbackButton from 'components/FeedbackButton/FeedbackButton';

export interface ISpecialistPreviewBase {
    onClick?: () => void;
    showNearestTimeslots?: boolean;
    onClickTimeSlot?: (date: SpecialistNearestTimeslotType['date'], value: TTimeSlot) => void;
    showAboutButton?: boolean;
    className?: string;
    avatarClassName?: string;
    avatarImageClassName?: string;
    specialist: SpecialistDetails;
    showFeedbackButton?: boolean;
}

const SpecialistPreviewBase: FCC<ISpecialistPreviewBase> = ({
    specialist,
    onClick,
    showNearestTimeslots,
    onClickTimeSlot,
    showAboutButton = true,
    className,
    avatarClassName,
    avatarImageClassName,
    showFeedbackButton,
}) => {
    const {
        id,
        name: employeeName,
        active,
        languages,
        image,
        nearestTimeslots,
        feedbacks,
        clientLastOrderNotFeedback
    } = specialist;

    const name = employeeName;

    // TODO: Refactoring: Feedaback no props BAR-578
    const feedbacksCount = feedbacks?.count || null;
    // TODO: Refactoring: Feedaback no props BAR-578
    const feedbacksRating = feedbacks?.rating || null;

    return (
        <div className={cn(styles.component, className, {[styles.disabled]: !active && active !== undefined})}>
            <div className={styles.generalSection}>
                <div className={styles.mainInfo} onClick={onClick}>
                    <div className={cn(styles.avatarBox, avatarClassName)}>
                        <Avatar alt={name} className={avatarImageClassName} src={image}>{name.substr(0,2).toUpperCase()}</Avatar>
                    </div>
                    <div className={styles.additionalInfo}>
                        <div>
                            <p className={styles.name}>{name}</p>
                            {languages?.length && (
                              <p className={styles.languages}>{languages.join(', ')}</p>
                            )}
                            <div className={styles.ratingBox}>
                                {/* TODO: Refactoring: Feedaback no props BAR-578*/}
                                {feedbacksRating && <Rating name="read-only" value={feedbacksRating} readOnly className={styles.rating}/>}
                                {/* TODO: Refactoring: Feedaback no props BAR-578*/}
                                {feedbacksCount && <span className={styles.ratingCount}>({feedbacksCount})</span>}
                            </div>
                        </div>
                        {showFeedbackButton && clientLastOrderNotFeedback && (
                          <FeedbackButton orderHash={clientLastOrderNotFeedback}/>
                        )}
                    </div>
                </div>
                {showAboutButton && (
                    <Link to={`${window.location.pathname}/${id}`}>
                        <InfoOutlinedIcon className={styles.infoIcon}/>
                    </Link>
                )}
            </div>
            {showNearestTimeslots && nearestTimeslots && nearestTimeslots?.timeslots?.length > 0  && onClickTimeSlot && (
                <NearestTimeslots onClickTimeSlot={onClickTimeSlot} nearestTimeslots={nearestTimeslots}/>
            )}

        </div>
    )
}

export default SpecialistPreviewBase;
