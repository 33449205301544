import React, { FC } from 'react';

import { OrderDataExtended } from 'components/types';
import UserOrdersListItem from 'components/UserOrdersListItem/UserOrdersListItem';
import {i18nt} from 'translations/i18n';
import NoData from 'components/NoData/NoData';

import styles from './UserOrdersList.module.scss';

export interface UserOrdersListProps {
    orders: OrderDataExtended[];
    translationKey?: string;
}

const UserOrdersList: FC<UserOrdersListProps> = ({orders}) => {

    if(!orders.length) return <NoData title={i18nt('THERE_ARE_NO_ORDERS_YET')}/>

    return (
        <div className={styles.component}>
            {orders.map(order => (
                <UserOrdersListItem order={order} key={`order-${order.id}`}/>
            ))}
        </div>
    )
}

export default UserOrdersList;
