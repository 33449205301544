import React, {ChangeEvent, KeyboardEvent, ReactText, useEffect, useState} from 'react';
import { FCC } from 'types';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './CodeVerification.module.scss';
import {useEffectOnce} from "react-use";

const KEY_CODE = {
    backspace: 8,
    left: 37,
    up: 38,
    right: 39,
    down: 40
};

export interface CodeVerificationProps {
    className?: string;
    numbers?: string;
    onComplete: (value: string) => void;
}

const CodeVerification: FCC<CodeVerificationProps> = ({ numbers = 6, className, onComplete }) => {

    const [numbersBlocks, setNumbersBlocks] = useState<ReactText[]>([]);

    //@ts-ignore
    const [value, setValue] = useState<string[]>([...new Array(numbers).fill('')]);
    const ref = React.useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        setNumbersBlocks(new Array(numbers).fill(''))
    }, [numbers])

    useEffect(() => {
        console.log('ref.current', ref.current)
        if(ref.current) {
            setTimeout(() => {
                goToNextInput(0)
            }, 0)

        }

    }, [ref.current])
    useEffect(()=> {
        validateValue(value);
    }, value)

    const goToNextInput = (nextInputIndex: number,) => {
        if(nextInputIndex === numbers || nextInputIndex < 0) {
            return;
        }

        if (ref.current) {
            ref.current.querySelectorAll('input')[nextInputIndex].focus()
        }
    }

    const updateValueByIndex = (index: number, inputValue: string) => {
        setValue(value => {
            const newValue = [...value];
            newValue[index] = inputValue;
            return newValue;
        })
    }

    const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
        const inputValueOrigin = e.target.value.replace(/[^\d]/g, "").trim()
        const inputValue = inputValueOrigin.substr(inputValueOrigin.length - 1, inputValueOrigin.length)

        if(inputValue.length) {
            goToNextInput(index + 1);

            updateValueByIndex(index, inputValue)
        }
    }

    const handleInputKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
        const keyCode = e.keyCode;
        if(keyCode === KEY_CODE.left) {
            goToNextInput(index - 1)
            return;
        }
        if(keyCode === KEY_CODE.right) {
            goToNextInput(index + 1)
            return;
        }
        if(keyCode === KEY_CODE.backspace) {
            if(!value?.[index]?.length) {
                goToNextInput(index - 1)
            }
            updateValueByIndex(index, '')
            return;
        }
    }

    const validateValue = (_value: string[]) => {
        const isFilled = _value.length === numbers && _value.every(i => i.length);
        if(isFilled) {
            onComplete(_value.join(''))
        }
    }
    // console.log(focusedInput);
    return (
        <div className={cn(styles.component, className)} ref={ref}>
            {numbersBlocks.map((i, index) => (
                <input
                    type="text"
                    className={styles.input}
                    onChange={handleInputChange(index)}
                    onKeyDown={handleInputKeyDown(index)}
                    value={value[index]}
                />
            ))}
        </div>
    )

}

export default CodeVerification;