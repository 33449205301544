import React from 'react';
import cn from 'classnames';

import UserOrdersList from 'components/UserOrdersList/UserOrdersList';
import {i18nt} from 'translations/i18n';
import Tabs from 'kit/Tabs/Tabs';
import Tab from 'kit/Tabs/Tab/Tab';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import {useUserOrders} from 'components/pages/UserOrdersPage/UserOrdersProvider';

import styles from './UserOrdersContent.module.scss';

const UserOrdersContent = () => {

  const {doneOrdersState, newOrdersState , currentOrdersState, currentStatus,setCurrentStatus, isLoading} = useUserOrders();

  const triggerSortOrder = () => {
    currentOrdersState.setSortOrder(currentOrdersState.sortOrder === 'asc' ? 'desc' : 'asc');
  }

  return (
    <div className={cn('UserOrdersContent_component', styles.component)}>
      <div className={styles.header}>
        <Tabs
          className={styles.tabs}
          value={currentStatus}
          onChange={(e, newValue) => setCurrentStatus(newValue)}
          aria-label="basic tabs example">
          <Tab label={i18nt('ACTUAL')} value="new"/>
          <Tab label={i18nt('PAST')} value="done"/>
        </Tabs>

        <div onClick={triggerSortOrder}>
          <Icon
            className={styles.sortIcon}
            name={currentOrdersState.sortOrder === 'asc' ? 'sortingAsc' : 'sortingDesc'}
          />
        </div>

      </div>

      {isLoading && <Spinner/>}

      {!isLoading && (
        <>
          {currentStatus === 'new' && (
            <UserOrdersList orders={newOrdersState.orders}/>
          )}
          {currentStatus === 'done' && (
            <UserOrdersList orders={doneOrdersState.orders}/>
          )}
        </>
      )}

    </div>
  )
}

export default UserOrdersContent;
