import React, {useEffect} from 'react';
import {FCC} from 'types';

import styles from './ClassNameWrapper.module.scss';

interface ClassNameWrapperProps {
  elementClassName?: string;
  className?: string;
  isContent?: boolean;
}

const ClassNameWrapper: FCC<ClassNameWrapperProps> = ({elementClassName, className, isContent,children}) => {

  useEffect(() => {
    const element= `.${elementClassName}`;
    let additionalClassName = className || '';
    if(isContent) {
      additionalClassName = styles.isContent
    }

    if(element && additionalClassName){
      const elements = document.querySelectorAll(element);

      [...elements].forEach((el) => {

        el.classList.add(additionalClassName);
      })
    }

    return () => {
      if(element && additionalClassName){
        const elements = document.querySelectorAll(element);
        [...elements].forEach((el) => {
          el.classList.remove(additionalClassName);
        })
      }
    }
  }, [])

  return (
    <>
      {children}
    </>
  );
}

export default ClassNameWrapper;
