import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {toCamelCase, toSnakeCase} from 'utils/apiUppercase';
import {getStorageToken} from 'utils/user';
import * as process from 'process';

const AUTH_PREFIX = 'auth.';
export const isDevelopmentMode = process.env.NODE_ENV === 'development';
const URL_PREFIX = isDevelopmentMode ? 'http://' : 'https://';
const DEV_URL_HOST = process.env.REACT_APP_DEVELOPMENT_HOST;
const URL_HOST = process.env.REACT_APP_URL_HOST; // Example stage from .env file

export const LOCATION_ORIGIN = `${URL_PREFIX}${DEV_URL_HOST || URL_HOST}`;
export const AUTH_LOCATION_ORIGIN = isDevelopmentMode ? LOCATION_ORIGIN :`${URL_PREFIX}${AUTH_PREFIX}${DEV_URL_HOST || URL_HOST}`;

const apiGenerator = () => {
    const ownerIdHash = location.hostname.split('.')[0];
    const baseURL = `https://${ownerIdHash}.${URL_HOST}/api`;
    const instance = axios.create({
        baseURL,
    });

    //@ts-ignore
    window.appInfo = {
        //@ts-ignore
        ...(window?.appInfo || {}),
        APP_VERSION: process.env.REACT_APP_VERSION,
        apiUrl: baseURL,
    }

    instance.interceptors.request.use(function (config) {
        // Do something before request is sent
        const token = getStorageToken();

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        //@ts-ignore
        const transFormCase = config?.transformCase !== false ? true: config?.transformCase;

        // console.log({transFormCase})
        if(transFormCase) {
            return  {
                ...config,
                data: toSnakeCase(config.data)
            }
        }

        return config;
    }, function (error) {
        // alert(1)
        // Do something with request error
        return Promise.reject(error);
    });

    instance.interceptors.response.use(function (response) {
        // console.log(response, args)
        //@ts-ignore
        const transFormCase = response.config?.transformCase !== false ? true: response.config?.transformCase

        if(response.data && transFormCase) {
            return  {
                ...response,
                data: toCamelCase(response.data)
            }
        }

        return response;
    }, function (error) {

        // isDevelopmentMode && console.log(error);
        if(error.response) {
            return  Promise.reject(toCamelCase(error.response))
        }
        // Do something with response error
        return Promise.reject(error);
    });

    const get = function <T, R = AxiosResponse<T>>(url: string, query?: any, config?: AxiosRequestConfig) {

        const localQuery = query ? `?${new URLSearchParams(toSnakeCase(query)).toString()}` : '';

        //@ts-ignore
        return instance.get(`${url}${localQuery}`, {...config})
    };


    const post = function <T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
        return instance.post(url, data, config)
    };

    const put = function <T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
        return instance.post(url, data, config)
    };

    const patch = function <T, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
        return instance.post(url, data, config)
    };

    const deleteFunc = function <T, R = AxiosResponse<T>>(url: string, data?: any) {
        return instance.delete(url, data)
    };

    return {
        get,
        post,
        put,
        patch,
        delete: deleteFunc,
    }
}

// @ts-ignore
const api = apiGenerator();
// @ts-ignore
export default api;
