import React, { useEffect, useState } from 'react';
import { FCC } from 'types';
import queryString from 'query-string';
import { addDays, format } from 'date-fns';
import cn from 'classnames';

import { apiGetTimeslotsList } from 'api/calendar';
import { toSnakeCase } from 'utils/apiUppercase';
import { useOrganization } from 'providers/OrganizationProvider';
import { i18nt } from 'translations/i18n';
import { dateFormat } from 'utils/dateUtil';

import TimeSlotItem from 'components/TimeSlotItem/TimeSlotItem';
import HeaderArrowNavigation from 'components/HeaderArrowNavigation/HeaderArrowNavigation';
import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import Content from 'components/Content/Content';
import Spinner from 'components/Spinner/Spinner';
import NoData from 'components/NoData/NoData';
import { useStoreProviderContext } from "providers/StoreProvider/StoreProvider";

import styles from './TimeSlots.module.scss';

const TimeSlots: FCC = () => {
  const { organization, navigateToOrganizationPage } = useOrganization();

  const { date, updateDateByDay, handleTime, specialist, services, setDate } = useStoreProviderContext();
  const prevDayDisabled = format(new Date(), 'yy-MM-dd') === format(new Date(date as Date), 'yy-MM-dd')

  const [ availableTimeSlots, setAvailableTimeSlots ] = useState<string[]>();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    if(!date) {

      return navigateToOrganizationPage('date')
    }
    setIsLoading(true)

    const query = {
      date: format(date as Date, 'yyyy-MM-dd'),
    }
    if (specialist?.id) {
      // @ts-ignore
      query['employeeId'] = specialist?.id;
    }

    if (services?.length && services?.length > 0) {
      // @ts-ignore
      query['servicesIds'] = services.map(item => item.id);
    }


    apiGetTimeslotsList({
      organizationId: organization!.id,
      query: queryString.stringify(toSnakeCase(query), { arrayFormat: 'comma' })
    })
      .then((result) => {
        //@ts-ignore
        const schedules = result.data;
        //@ts-ignore
        setAvailableTimeSlots(schedules || [])
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [ date ])

  return (
    <div className={cn('TimeSlots_component', styles.component)}>
      <OrderPageHeader defaultBackButton backButton={{ onClick: () => {
          setDate(null);
          navigateToOrganizationPage('date')
        } }}>
        <HeaderArrowNavigation
          prevButton={{
            onClick: () => updateDateByDay(addDays(date as Date, -1)),
            disabled: prevDayDisabled
          }}
          nextButton={{
            onClick: () => updateDateByDay(addDays(date as Date, 1)),
            disabled: false
          }}
        >
          {date && (
            <div className={styles.dateBox}>
              <span className={styles.day}>{dateFormat(date, 'EEEE')}</span>
              <span className={styles.month}>{dateFormat(date, 'dd')} {dateFormat(date, 'MMMM')}</span>
            </div>
          )}
        </HeaderArrowNavigation>
      </OrderPageHeader>
      <Content>
        {isLoading && <Spinner isFullPage/>}
        <div className={styles.content}>
          {!isLoading && availableTimeSlots && availableTimeSlots.map(availableTimeSlot => (
            <TimeSlotItem onClick={() => handleTime(availableTimeSlot)}
                          key={`TimeSlots-${availableTimeSlot}`}>{availableTimeSlot}</TimeSlotItem>
          ))}
        </div>
        {!isLoading && availableTimeSlots?.length === 0 && (
          <NoData title={i18nt('THERE_ARE_NO_FREE_SEATS_FOR_THIS_DAY')} />
        )}
      </Content>

    </div>
  )
}

export default TimeSlots;