import {useEffect, useState} from "react";
import api from "../api/api";
import convertThemeToStyles from 'utils/convertThemeToStyles';
import preloadImage from 'utils/preloadImage';

export interface useCustomCssProps {
    uuid: string;
}

export interface useCustomCssResponse {
    loading: boolean;
    inIframe: () => boolean;
}

export type ThemeType = 'default' | 'glass';

export type AppThemeDTO = {
    themeName?: ThemeType;
    brandColor?: string;
    textColor?: string;
    headerBackgroundColor?: string;
    headerBackgroundImage?: string;
    layoutBackgroundColor?: string;
    layoutBackgroundImage?: string;
    glassMain?: number;
    glassContent?: number;
}

export type ButtonThemeDTO = {
    color?: string;
    rightMargin?: number;
    bottomMargin?: number;
    textColor?: string;
    text?: string
}

export type ThemesDTO = {
    appActiveTheme: ThemeType,
    app: {
        default: AppThemeDTO;
        glass: AppThemeDTO;
    },
    button: ButtonThemeDTO;
}


export type colorSchemeType = {
    brandColor: string,
    textColor: string,
    headerBackgroundColor: string,
    headerBackgroundImage: string,
    layoutBackgroundImage?: string;
    layoutBackgroundColor?: string;
}

export function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

const useCustomCss = ({ uuid } : useCustomCssProps): useCustomCssResponse => {

    const [loading, setLoading] = useState<boolean>(true);


    const applyToDom = (data: ThemesDTO) => {
        const currentTheme = data?.appActiveTheme;
        if(inIframe() || !currentTheme) return;

        const { isGlassMode, styles} = convertThemeToStyles(data.app[currentTheme]);

        const body = document.getElementsByTagName('body')[0];
        const stylesTag = document.createElement('style');
        stylesTag.innerHTML = styles;


        //THEME Related
        if(isGlassMode) {
            body.classList.add('glass-theme');
        }


        body.append(stylesTag);
    }

    useEffect(() => {
        setLoading(true);

        api.get(`/theming`)
            .then((result) => {
                applyToDom(result.data);

                const imageLink = result?.data?.app[result?.data?.appActiveTheme]?.layoutBackgroundImage;
                if(imageLink) {
                    return preloadImage(imageLink).then(() => {
                        // console.log('>> image loaded')
                    });
                }
            })
            .catch(() => {
                // console.log('error');
                // applyToDom();
            })
          .finally(() => {
              // console.log('>> finally');
              setLoading(false);
          })
    }, [uuid])

    return {
        loading,
        inIframe,
    }

}

export default useCustomCss;