import React from 'react';
import { FCC } from 'types';
import cn from "classnames";

import {TextFieldProps} from "@mui/material/TextField/TextField";
import PhoneInputComponent, { IPhoneInputProps } from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'

import styles from './PhoneInput.module.scss';



export type InputProps = Omit<TextFieldProps, 'onChange'> & IPhoneInputProps & {
    className?: string
}

const countriesNotSupportingUkraine = [
    'CN', // China
    'IN', // India
    'BR', // Brazil
    'ZA', // South Africa
    'VE', // Venezuela
    'CU', // Cuba
    'IR', // Iran
    'SY', // Syria
    'KP', // North Korea
    'by', // Belarus
    'CF', // Central African Republic
    'VN', // Vietnam
    'LA', // Laos
    'MM', // Myanmar
    'KH', // Cambodia
    'ET', // Ethiopia
    'ru', // Russian
];

const PhoneInput: FCC<InputProps> = ({className, error, helperText, ...props }) => {
    const isError = error && helperText;
    return (
        <div className={cn(styles.component, {[styles.error]: isError}, className)}>
            <PhoneInputComponent {...props} preferredCountries={['ua', 'hr']} excludeCountries={countriesNotSupportingUkraine}/>
          {isError  && (
            <div className={styles.errorText}>
              {helperText}
            </div>
          )}
        </div>
    )
}

export default PhoneInput;
