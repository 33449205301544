import React, {FC, useState} from 'react';
import cn from 'classnames';
import TextField from '@mui/material/TextField';
import {TextFieldProps} from '@mui/material/TextField/TextField';

import styles from './TextArea.module.scss'

type Props  = TextFieldProps & {
  textFieldClassName?: string;
}

const TextArea: FC<Props> = ({className, textFieldClassName, label, rows = 4, multiline = true, ...props}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={cn(styles.component, isFocused && styles.focused, className)}>
      {label && (
        <div className={cn(styles.label, isFocused && styles.labelFocused)}>
          {label}
        </div>
      )}
      <TextField
        {...props}
        InputLabelProps={{
          shrink: true,
          ...(props.InputLabelProps || {}),
        }}
        // variant="standard"
        variant="outlined"
        multiline
        rows={rows}
        className={cn(styles.textField, textFieldClassName)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  )
}

export default TextArea;
