import React, {useMemo} from 'react';
import cn from 'classnames';
import {useParams} from 'react-router-dom';

import Button, {ButtonProps} from 'components/Button/Button';
import {i18nt} from 'translations/i18n';

type Props = Partial<ButtonProps<any>> & {
  orderHash: string | null,
}

const FeedbackButton = ({ children, className, orderHash, ...props}: Props) => {
  const params  = useParams<{organizationId: string, orderHash: string}>();
  const backRef = window.location.pathname;

  const {organizationId} = useMemo(() => ({
    organizationId: Number(params.organizationId),
  }), [params]);

  const feedbackLink = `/organization/${organizationId}/feedback/${orderHash}?backRef=${backRef}` as string;

  return (
    <Button variant="primary" component="link" className={cn('FeedbackButton_component', className)} to={feedbackLink} {...props}>
      {children || i18nt('LEAVE_A_REVIEW')}
    </Button>
  )
}

export default FeedbackButton;