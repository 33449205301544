import React, {useCallback} from 'react';
import cn from 'classnames';

import CalendarNavigation from "./CalendarNavigation/CalendarNavigation";
import {useStoreProviderContext} from "providers/StoreProvider/StoreProvider";
import { useOrganization } from 'providers/OrganizationProvider';
import DatePicker from "../../DatePicker/DatePicker";

import styles from './DatePage.module.scss'

const DatePage = () => {
    const { navigateToOrganizationPage } = useOrganization();
    const { date, time, setDate } = useStoreProviderContext();
    const handleBackClick = () => {
        if(!time) {
            setDate(null);
        }
        navigateToOrganizationPage()
    }
    const innerHandleData = (date: Date | null) => {
        setDate(date);
        navigateToOrganizationPage('time')
    }

    const renderDateCustomHeader = useCallback((props: any) => <CalendarNavigation handleBackClick={handleBackClick} {...props}/>, [])

    return (
        <div className={cn(styles.component)}>
            <DatePicker onChange={innerHandleData} date={date} renderDateCustomHeader={renderDateCustomHeader} />
        </div>
    )
}

export default DatePage;