import React, {FC, ReactText} from 'react';
import MuiSelect from '@mui/material/Select';
import NativeSelect, {NativeSelectProps} from '@mui/material/NativeSelect';
import MenuItem from '@mui/material/MenuItem';
import cn from "classnames";
import { FCC } from 'types';

export interface ISelectOption {
    label: ReactText;
    value: ReactText;
}

export interface ISelect {
    options: ISelectOption[];
    onChange: (value: ISelectOption['value']) => void;
    variant?: NativeSelectProps['variant'];
    className?: string;
    value?: ISelectOption['value'];
}

const Select: FCC<ISelect> = ({ className,options, variant = "standard", value, onChange}) => {

    const isMobile = true;

    const handleChange = (event: { target: { value: ISelectOption['value'] } }) => {
        onChange(event.target.value);
    };

    return (
        <>
            {isMobile && (
                <NativeSelect
                    onChange={handleChange}
                    variant={variant}
                    value={value}
                    className={cn('Select_component', className)}
                >
                    {options.map(option => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </NativeSelect>
            )}
            {!isMobile && (
                <MuiSelect
                    onChange={handleChange}
                    variant={variant}
                    //@ts-ignore
                    value={value}
                    className={cn('Select_component', className)}
                >
                    {options.map(option => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                </MuiSelect>
            )}
            </>
    )
    // if(isMobile) {
    //     return (
    //         <NativeSelect onChange={handleChange} variant={variant}>
    //             {options.map(option => (
    //                 <option value={option.value}>{option.label}</option>
    //             ))}
    //         </NativeSelect>
    //     )
    // }
    // return (
    //     <MuiSelect onChange={handleChange} variant={variant}>
    //         {options.map(option => (
    //             <MenuItem value={option.value}>{option.label}</MenuItem>
    //         ))}
    //     </MuiSelect>
    // )
    //@ts-ignore
    // return <Component {...props} />
}

export default Select;