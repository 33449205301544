import React from 'react';

import { useOrganization } from 'providers/OrganizationProvider';
import AboutInformation from 'components/AboutInformation/AboutInformation';

import styles from './HomePage.module.scss'

const HomePage = ({}) => {
    const { organization } = useOrganization();

    if(!organization) return (<div>'No organization'</div>);

    return (
        <div className={styles.component}>
            <AboutInformation className={styles.aboutInformation} />
        </div>
    )
}

export default HomePage;