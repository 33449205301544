import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextHighlighter from "components/TextHighlighter/TextHighlighter";
import {ServiceItemDTO, ServiceSubItemDTO} from "../types";

import styles from './ServiceListCategory.module.scss';
import ServiceListItem from "../ServiceListItem/ServiceListItem";
import { FCC } from 'types';

export interface ServiceListCategoryProps extends ServiceItemDTO {
    onChange: (item: ServiceSubItemDTO, value: boolean) => void;
    openState: boolean;
    handleOpenState: (state: boolean) => void;
    searchValue?: string;
    selectedServices: ServiceSubItemDTO[];
    isPreselectedQuery?: boolean;
}

const ServiceListCategory: FCC<ServiceListCategoryProps> = ({ searchValue, image, name,items, onChange, handleOpenState, openState, selectedServices, isPreselectedQuery }) => {
    const labelAvailablity = isPreselectedQuery ? `${items.filter(item => item.active).length}/${items.length}` : null;

    return (
        <div className={styles.component}>
            <Accordion onChange={(e, expanded) => handleOpenState(expanded)}  expanded={openState}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div>
                        {image && (
                            <div className={styles.mainImage}>
                                <img src={image} alt={name}/>
                            </div>
                        )}
                    </div>
                    <div className={styles.categoryNameLine}>
                        <TextHighlighter
                            search={searchValue}
                            text={name}
                        />
                        <span className={styles.labelAvailability}>
                            {labelAvailablity}
                        </span>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {items.map(item => (
                        <ServiceListItem
                          item={item}
                          onChange={onChange}
                          className={styles.subItem}
                          searchValue={searchValue}
                          //@ts-ignore
                          selected={!!selectedServices.find((selectedService: ServiceSubItemDTO) => selectedService.id === item.id)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ServiceListCategory;
