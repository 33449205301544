import React from 'react';

import Icon from 'components/Icon/Icon';
import {AUTH_LOCATION_ORIGIN} from 'api/api';

import styles from './SocialAuth.module.scss';

type Props = {
  redirectUrl?: string;
}
const SocialAuth = ({redirectUrl}: Props) => {
  const handleClick= () => {
    window.location.href = `${AUTH_LOCATION_ORIGIN}/auth?auth=google&redirectUrl=${redirectUrl}`;
  }

  return (
    <div className="SocialAuth_component">
      <button className={styles.button} onClick={handleClick}>
        <Icon name="googleColor" className={styles.icon}/>
        Sign in with Google
      </button>

    </div>
  )
}

export default SocialAuth;
