import React, {FC} from 'react';

import {i18nt} from 'translations/i18n';
import {useAuth} from 'providers/AuthProvider';
import {useOrganization} from 'providers/OrganizationProvider';
import Spinner from 'components/Spinner/Spinner';
import NoData from 'components/NoData/NoData';
import UserOrdersContent from 'components/pages/UserOrdersPage/UserOrdersContent/UserOrdersContent';
import UserHelloBlock from 'components/pages/UserOrdersPage/UserHelloBlock/UserHelloBlock';
import AuthForm from 'components/AuthForm/AuthForm';
import OrderPageHeader from 'components/OrderPageHeader/OrderPageHeader';
import UserOrdersProvider, {useUserOrders} from 'components/pages/UserOrdersPage/UserOrdersProvider';


import styles from './UserOrdersPage.module.scss';

const UserOrdersPage: FC = () => {
  const {user} = useAuth();
  const {navigateToOrganizationPage} = useOrganization();
  const {isLoading, noData} = useUserOrders();

  return (
    <div className={styles.component}>
      <OrderPageHeader
        defaultBackButton title={i18nt('MY_APPOINTMENTS')}
        backButton={{onClick: () => navigateToOrganizationPage()}}
      />
      <div className={styles.content}>
        {!user && (
          <AuthForm/>
        )}

        {user && (
          <>
            <UserHelloBlock/>
            {!isLoading && noData && (
              <NoData title={i18nt('THERE_ARE_NO_ORDERS_YET')}/>
            )}
            {isLoading && !noData && (
              <Spinner isFullPage/>
            )}

            {!isLoading && !noData && (
              <UserOrdersContent />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default () => (
  <UserOrdersProvider>
    <UserOrdersPage/>
  </UserOrdersProvider>
)
