import React from 'react';
import cn from 'classnames';

import './dots.css';
import './DotsLoader.module.scss';

type Props = {
  className?: string;
}
const DotsLoader = ({className}: Props) => {
  return (
    <div className={cn('loader', className)}>
      <div className="loader__element"/>
      <div className="loader__element"/>
      <div className="loader__element"/>
    </div>
  )
}

export default DotsLoader;
