import React  from 'react';
import { Route, Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import AppStateProvider from 'providers/AppStateProvider';
import OrganizationsProvider from 'providers/OrganizationsProvider';
import InnerPages from 'components/Routing/InnerPages';

import OrganizationsPage from "../pages/OrganizationsPage/OrganizationsPage";

const Routing = () => {


    return (
        <div className="Routing_component">
            <AppStateProvider>
                <OrganizationsProvider>
                    <Routes>
                        <Route index element={<OrganizationsPage />} />
                        <Route path="/auth" element={<OrganizationsPage />} />
                        <Route path="/organization/:organizationId" element={<InnerPages />} />
                        <Route path="/organization/:organizationId/*" element={<InnerPages />} />
                    </Routes>
                </OrganizationsProvider>
            </AppStateProvider>
        </div>
    )
}

export default withTranslation()(Routing);