import { enGB, uk, fr, hr } from 'date-fns/locale';
import {format} from 'date-fns';
import i18n from 'translations/i18n';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import { formatInTimeZone } from 'date-fns-tz';

export const dateFnsLocaleMapper = {
  ua: uk,
  en: enGB,
  fr,
  hr,
}

type LocaleKey = keyof typeof dateFnsLocaleMapper;

const updateCalendarLocale = (localeKey: LocaleKey) => {
  if(localeKey === 'ua') {
    registerLocale('uk', dateFnsLocaleMapper.ua);
    setDefaultLocale('uk');

    return;
  }

  registerLocale(localeKey, dateFnsLocaleMapper[localeKey]);
  setDefaultLocale(localeKey);
}


export const globalLocale = {
  // @ts-ignore
  key: i18n.resolvedLanguage === 'uk' ? 'ua' : (dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'),
  //@ts-ignore
  locale: dateFnsLocaleMapper[dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'] as Locale
}
setDefaultOptions({ locale: globalLocale.locale })


updateCalendarLocale(globalLocale.key as LocaleKey);

// console.log({globalLocale});

export const dateFormats = {
  day: 'dd',
  dayName: 'EEEE',
  time: 'HH:mm',
  month: 'MMMM',
  date: 'dd-MM-yyyy',
  dateView: 'yyyy-MM-dd',
  dayMonthName: 'dd MMMM',
  dateAndTime: 'dd-MM-yyyy HH:mm',
}

export const dateFormat = (date: Date, formatString?: any) =>
  format(date, formatString || dateFormats.date, {
    locale: globalLocale.locale // or global.__localeId__
  })

export type dateFormatInTZArgs = {
  date: Date,
  formatString?: any,
  tz?: string
}
export const dateFormatInTZ = ({date, formatString, tz}: dateFormatInTZArgs) =>
  formatInTimeZone(date, tz || 'Europe/Kyiv', formatString || dateFormats.date, {locale: globalLocale.locale })


// const formatRelativeLocale = {
//   lastWeek: '[letzten] dddd [um] LT',
//   yesterday: '[gestern um] LT',
//   today: '[heute um] LT',
//   tomorrow: '[morgen um] LT',
//   nextWeek: 'dddd [um] LT',
//   other: 'L LT', // Difference: Add time to the date
// };

// const locale = {
//   ...de,
//   formatRelative: token => formatRelativeLocale[token],
// };

// const text = formatRelative(date, new Date(), { locale });

// export const formatRelativeLocale = (date: Date, baseDate: Date) =>
//   formatRelative(date, new Date(), { locale: globalLocale.locale });

export const updateLocale = (localeKey: string) => {
  i18n.changeLanguage(localeKey)
  //@ts-ignore
  globalLocale.key = dateFnsLocaleMapper[localeKey] ?  i18n.resolvedLanguage : 'en';
  //@ts-ignore
  globalLocale.locale = dateFnsLocaleMapper[dateFnsLocaleMapper[i18n.resolvedLanguage] ?  i18n.resolvedLanguage : 'en'];

  // @ts-ignore
  setDefaultOptions({ locale: globalLocale.locale })

  updateCalendarLocale(globalLocale.key as LocaleKey);
};