import React from 'react';

import styles from './OrganizationGallery.module.scss';
import Slider from 'components/Slider/Slider';
import MapEmbed from 'components/Map/MapEmbed';
import cn from 'classnames';

export type MapItemData = {
  address?: string;
  coordinates?: string;
}

export type GalleryItem = {
  type: 'image' | 'map';
  data?: string | MapItemData;
}

interface Props {
  items: GalleryItem[];
}

const OrganizationGallery = ({items}: Props) => {

  return (
    <Slider className={styles.component}>
      {items.map((item, index) => {
          if (item.type === 'image') {
            return (
              <div key={index} className={cn(styles.item, styles.image)}>
                <img src={item.data as string}/>
              </div>
            )
          }

          if (item.type === 'map') {

            //@ts-ignore
            return <MapEmbed mapAddress={item.data?.coordinates || item.data?.address} key={index} className={cn(styles.item, styles.map)}/>;
            // return (
            //   <div key={index} className={cn(styles.item, styles.map)}>
            //     {/*@ts-ignore*/}
            //     <MapEmbed mapAddress={item.data?.coordinates || item.data?.address} key={index} className={cn(styles.item, styles.map)}/>
            //   </div>
            // )
          }
        }
      )}

      {/*<div>*/}
      {/*  <img src="http://placekitten.com/g/400/200" />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  <img src="http://placekitten.com/g/400/200" />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  <img src="http://placekitten.com/g/400/200" />*/}
      {/*</div>*/}
        </Slider>
        )
      }

export default OrganizationGallery