import React, {useMemo} from 'react';
import {FCC} from 'types';
import cn from 'classnames';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextHighlighter from 'components/TextHighlighter/TextHighlighter';
import Checkbox from 'components/Checkbox/Checkbox';
import Icon from 'components/Icon/Icon';
import {ServiceSubItemDTO} from '../types';

import styles from './ServiceListItem.module.scss';
import {i18nt} from 'translations/i18n';

export interface ServiceListItemProps {
  onChange: (item: ServiceSubItemDTO, value: boolean) => void;
  searchValue?: string;
  item: ServiceSubItemDTO;
  className?: string;
  selected: boolean;
}

const ServiceListItem: FCC<ServiceListItemProps> = ({className, searchValue, item, onChange, selected}) => {
  const {id, image, name, price, currency, active, notAvailableByOneEmployee} = item;

  const itemDuration = useMemo(() => {
    const {duration, durationMin, durationMax} = item;

    if (durationMin && (durationMin === durationMax)) return durationMin;

    if (durationMin && (durationMin !== durationMax)) return `${durationMin} - ${durationMax}`;

    return duration;
  }, [item]);

  return (
    <div
      className={cn(styles.component, className, {[styles.disabled]: !active})}
      key={`service-list-sub-item-${id}`}
    >
      <div className={cn(styles.content, notAvailableByOneEmployee && styles.notAvailableByOneEmployee)}>
        {image && (
          <div className={styles.itemImage} onClick={() => onChange(item, !selected)}>
            <img src={image} alt={name}/>
          </div>
        )}
        <div>
          <FormControlLabel
            control={<Checkbox/>}
            onChange={(e, value) => onChange(item, value)}
            checked={selected}
            label={(
              <div>
                <div className={styles.name}>
                  <TextHighlighter
                    search={searchValue}
                    text={name}
                    className={styles.nameText}
                  />
                </div>
                <div className={styles.priceAndDuration}>
                  <div className={styles.price}>
                    {price} {currency}
                  </div>
                  <div className={styles.duration}>
                    <Icon name="clock" className={styles.durationIcon}/>
                    <span className={styles.durationValue}>
                        {itemDuration} хв
                    </span>
                  </div>
                </div>
              </div>
            )}/>
        </div>
      </div>
      {notAvailableByOneEmployee && (
        <div className={styles.notAvailableByOneEmployeeMessageBox}>
          <div className={styles.notAvailableByOneEmployeeMessageContent}>
            <span>
              {i18nt('NOT_AVAILABLE_BY_ONE_EMPLOYEE_MESSAGE')}
            </span>
          </div>
        </div>
      )}

    </div>
  )
}

export default ServiceListItem;
