import {i18nt} from 'translations/i18n';

const TEXTS = {
  REQUIRED_FIELD: 'Поле обов`язкове',
  EMAIL_INVALID: 'Невалідний email',
}

export const getValidationError = (key: string) => {

  const VALIDATION_ERRORS: Record<string, string> = {
    email_duplicated: i18nt('USER_WITH_THIS_EMAIL_ALREADY_EXISTS')
  };

  if(Object.keys(VALIDATION_ERRORS).includes(key)) {
    return VALIDATION_ERRORS?.[key];
  }

  return undefined;

}




export default TEXTS;
