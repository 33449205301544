import React from 'react';
import { FCC } from 'types';
import { useNavigate } from 'react-router-dom';

import {useStoreProviderContext} from 'providers/StoreProvider/StoreProvider';
import OrderList from 'components/OrderList/OrderList';

import OrderPageHeader from "../OrderPageHeader/OrderPageHeader";

import styles from './OrderPageListShort.module.scss';
import AboutInformation from 'components/AboutInformation/AboutInformation';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';

const OrderPageListShort: FCC = () => {
  const navigate = useNavigate();
  const { isLoading } = useStoreProviderContext();

  if(isLoading) return <SpinnerPage/>

  return (
    <div className={styles.component}>
      <div className={styles.aboutWrapper}>
        <OrderPageHeader title="" className={styles.navigationHeaader} backButton={{onClick: () => navigate('/') }}/>
        <AboutInformation />
      </div>
      <OrderList/>
    </div>
  )
}

export default OrderPageListShort;