import React from 'react';
import { FCC } from 'types';
import cn from "classnames";
import MUICheckbox, {CheckboxProps} from '@mui/material/Checkbox';

import styles from './Checkbox.module.scss';


export interface ICheckboxProps extends CheckboxProps{
    className?: string
}

const Checkbox: FCC<ICheckboxProps> = ({ className, ...props}) => (
    <MUICheckbox className={cn(styles.component, className)} {...props}/>
)

export default Checkbox;
