import React, { FC } from 'react';
import {addDays, format} from "date-fns";
import TimeSlotItem from "../TimeSlotItem/TimeSlotItem";

import styles from './NearestTimeslots.module.scss';
import {SpecialistDetails, SpecialistDTO, SpecialistNearestTimeslotType, TTimeSlot} from "../types";
import { i18nt } from 'translations/i18n';
import { FCC } from 'types';

export interface NearestTimeslotsProps {
    onClickTimeSlot: (date: SpecialistNearestTimeslotType['date'], value: TTimeSlot) => void;
    nearestTimeslots: SpecialistDetails['nearestTimeslots']
}

const NearestTimeslots: FCC<NearestTimeslotsProps> = ({ nearestTimeslots, onClickTimeSlot}) => {

    if(!nearestTimeslots) {
        return  null;
    }

    let nearestDayName = format(new Date(nearestTimeslots.date) as Date, 'dd MMMM' );

    const isToday = format(new Date() as Date, 'dd MMMM' ) === format(new Date(nearestTimeslots.date) as Date, 'dd MMMM' );
    const isTomorrow = format(addDays(new Date() as Date, 1) , 'dd MMMM' ) === format(new Date(nearestTimeslots.date) as Date, 'dd MMMM' );

    if(isToday) {
        nearestDayName = i18nt('TODAY')
    }

    if(isTomorrow) {
        nearestDayName = i18nt('TOMORROW')
    }

    return (
        <div className={styles.timeslots}>
            <div className={styles.timeslotsDate}>
                {i18nt('NEXT_APPOINTMENT_TIME')} {nearestDayName}
            </div>
            <div className={styles.timeslotsItems}>
                {nearestTimeslots.timeslots.map(timeslot => (
                    <TimeSlotItem onClick={() => onClickTimeSlot(nearestTimeslots.date, timeslot)}>{timeslot}</TimeSlotItem>
                ))}
            </div>
        </div>
    )
}

export default NearestTimeslots;
