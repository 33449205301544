import React from 'react'
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'

import { App } from 'app/App'
import { identifyLogRocket, initLogRocket } from 'utils/logrocketUtil';
import {inIframe} from 'hooks/useCustomCss';




initLogRocket();
// identifyLogRocket();

ReactModal.setAppElement('#root')
// ReactDOM.render(<App />, document.getElementById('root'))

const container = document.getElementById('root');
//@ts-ignore
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

var body = document.getElementsByTagName('body')[0];
const stylesTag = document.createElement('style');
body.append(stylesTag);

// const email = document.createElement("li")
window.addEventListener('message', event => {
    // IMPORTANT: check the origin of the data!
    // console.log(event.origin);
    // console.log(stylesTag);

    if (inIframe() && event.origin.startsWith('http://localhost:3001'|| 'https://my.gil.com.ua') || event.origin.includes('gil.com.ua')) {
        // The data was sent from your site.
        // Data sent with postMessage is stored in event.data:
        const {styles, isGlassMode} = event.data || {};

        if(!styles) {
            return;
        }
        stylesTag.innerHTML = styles;
        //THEME Related
        if(isGlassMode) {
            body.classList.add('glass-theme');
        } else {
            body.classList.remove('glass-theme');
        }
        // console.log('event.data');
        // console.log(event.data);
    } else {
        // The data was NOT sent from your site!
        // Be careful! Do not use it. This else branch is
        // here just for clarity, you usually shouldn't need it.
        return;
    }
});