import i18n from 'i18next';

function capitalize(s: string) {
  return s[0].toUpperCase() + s.slice(1);
}

export const getLanguageByKey = (languageKey: string) => {
  const languageLocalKey = languageKey.toLowerCase() === 'ua' ? 'UK' : languageKey;
  //@ts-ignore
  const lang = new Intl.DisplayNames([languageLocalKey], {type: 'language'})

  const languageName = lang.of(languageLocalKey);

  return languageName ? capitalize(languageName): languageName as string;
}

export const LANGUAGES_OPTIONS: {label: string; value: string}[] = Object.keys(i18n.store.data)
  .map((languageKey) => ({label: getLanguageByKey(languageKey), value: languageKey}))
  .filter(({value}) => value !== 'ua');


export const validateEmail = (email: string) =>  {
  // Regular expression to validate the email pattern
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if email matches the regex
  if (!emailRegex.test(email)) {
    return { valid: false, reason: 'Invalid email format' };
  }

  // Ensure the domain contains at least one period (.)
  const domain = email.split('@')[1];
  if (!domain.includes('.')) {
    return { valid: false, reason: 'Invalid domain format' };
  }

  // Check if the TLD is reasonable (2-24 chars)
  const tld = domain.split('.').pop();
  if (tld && (tld.length < 2 || tld.length > 24)) {
    return { valid: false, reason: 'Invalid top-level domain length' };
  }

  // Optionally check for common invalid domains (e.g., disposable email domains)
  const disposableDomains = ['mailinator.com', 'tempmail.com', '10minutemail.com'];
  if (disposableDomains.includes(domain)) {
    return { valid: false, reason: 'Disposable email address' };
  }

  return { valid: true, reason: 'Valid email' };
}