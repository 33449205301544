import React from 'react';
// import cn from 'classnames'
//
// import styles from './OrderMakeFooterGlobal.module.scss';
import OrderMakeFooter from 'components/OrderMakeFooter/OrderMakeFooter';
import {useStoreProviderContext} from 'providers/StoreProvider/StoreProvider';
// import {useLocation} from 'react-router-dom';

type Props = {
  className?: string;
}

const OrderMakeFooterGlobal = ({className}: Props) => {


  // const location = useLocation();
  const {transitionToRoute, date, time, services, specialist }  = useStoreProviderContext();


  const isOrderView = location.pathname.includes('/services') || location.pathname.includes('/specialists') || location.pathname.includes('/time');

  if(!isOrderView) return null;

  if(location.pathname.includes('/services') && services.length > 0) {
    // console.log('services page');
    // return  <OrderMakeFooter/>

    // if(date && time && services.length > 0 && specialist !== undefined) {
    //   return  <OrderMakeFooter/>
    // }

    return <OrderMakeFooter/>
  }


  return null;
};

export default OrderMakeFooterGlobal;
