import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import PeopleIcon from '@mui/icons-material/People';
import AllOutIcon from '@mui/icons-material/AllOut';

import { i18nt } from 'translations/i18n';
import OrderStatus from 'components/OrderPageList/OrderStatus/OrderStatus';
import { INITIAL_ORDER_DATA } from 'providers/StoreProvider/StoreProvider';
import Content from 'components/Content/Content';
import { organizationInnerRoute } from 'components/types';
import { dateFormat } from 'utils/dateUtil';
import { useStoreProviderContext } from 'providers/StoreProvider/StoreProvider';

import styles from 'components/OrderPageList/OrderPageList.module.scss';
import cn from 'classnames';

const OrderList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { date, time, services, specialist, handleOrderDataNoTransition, singleEmployee, singleService} = useStoreProviderContext();

  const handleNavigate = (subpage: organizationInnerRoute) => () => {
    navigate(`${location.pathname}/${subpage}`)
  }
  const dateAndTime = date && time ? `${dateFormat(new Date(date), 'dd MMMM')} ${time}` : null;

  const showSpecialists = !singleEmployee;
  const showServices = !singleService;
  const showSummary = Boolean(services && services?.length > 0);

  return (
    <>
      <Content className={styles.contentOverride}>
        <div className={styles.item} onClick={handleNavigate('date')}>
          <div className={styles.itemContent}>
            <div className={cn(styles.icon, !!date && styles.selectedIcon)}>
              <TodayIcon/>
            </div>
            <div className={styles.titleBlock}>
              <div className={styles.name}>
                {i18nt('DATE_AND_TIME')}
              </div>
              <div className={styles.selectedValue}>
                {dateAndTime && dateAndTime}
              </div>
            </div>
          </div>
          <OrderStatus isActive={!!date} onClick={() => {
            handleOrderDataNoTransition({
              date: INITIAL_ORDER_DATA.date,
              time: INITIAL_ORDER_DATA.time,
            })
          }}/>
        </div>
        {showSpecialists && (
          <div className={styles.item} onClick={handleNavigate('specialists')}>
            <div className={styles.itemContent}>
              <div className={cn(styles.icon, specialist !== undefined && styles.selectedIcon)}>
                <PeopleIcon />
              </div>
              <div className={styles.titleBlock}>
                <div className={styles.name}>
                  {i18nt('SPECIALIST')}
                </div>
                <div className={styles.selectedValue}>
                  {specialist?.name}
                  {/*{specialist === null && i18nt('DOES_NOT_MATTER_SPECIALIST')}*/}
                </div>
              </div>
            </div>
            <OrderStatus isActive={specialist !== undefined} onClick={() => {
              handleOrderDataNoTransition({
                specialist: INITIAL_ORDER_DATA.specialist,
              })
            }}/>
          </div>
        )}
        {showServices && (
          <div className={styles.item} onClick={handleNavigate('services')}>
            <div className={styles.itemContent}>
              <div className={cn(styles.icon, showSummary && styles.selectedIcon)}>
                <AllOutIcon />
              </div>
              <div className={styles.titleBlock}>
                <div className={styles.name}>
                  {i18nt('SERVICE')}
                </div>
                <div className={styles.selectedValue}>
                  {services?.map(service => service.name).join(', ')}
                </div>
              </div>
            </div>
            <OrderStatus isActive={showSummary} onClick={() => {
              handleOrderDataNoTransition({
                services: INITIAL_ORDER_DATA.services,
              })
            }}/>
          </div>
        )}
      </Content>
    </>
  )
}

export default OrderList;
