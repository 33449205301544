import {useEffect, useMemo, useRef, useState} from 'react';
import {useOrganization} from 'providers/OrganizationProvider';
import {useStoreProviderContext} from "providers/StoreProvider/StoreProvider";
import {apiGetOrderByHash} from 'api/order';
import {useParams} from 'react-router-dom';
import {setHours, setMinutes} from 'date-fns';
import {dateFormat} from 'utils/dateUtil';

const useOrderByHash = () => {
  const params = useParams();
  const { organization } = useOrganization()
  const { successOrder,setSuccessOrder } = useStoreProviderContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(!!successOrder);
  const preHashRef = useRef<string | null>();
  // const orderNotExisted = isLoaded && !successOrder;
  const isPastEvent = useMemo(() => {
    if(!successOrder) return false;
    let orderDate = new Date(successOrder.date);
    const orderTime = successOrder.timeEnd.split(':').map(item => Number(item));
    orderDate = setHours(orderDate,orderTime[0]);
    orderDate = setMinutes(orderDate,orderTime[1]);

    return  new Date().getTime() > new Date(orderDate).getTime();
  }, [successOrder])


  useEffect(() => {
    if(params.orderHash && organization?.id && preHashRef.current !== params.orderHash) {
      setIsLoaded(false)
      apiGetOrderByHash(params.orderHash,organization?.id)
        .then(result => {
          setSuccessOrder(result.data);
          setIsLoaded(true)
        })
        .catch(() => {
          setIsLoaded(true)
        })
    }
    preHashRef.current = params.orderHash;
  },[successOrder, params, organization]);

  // clear on unmount
  useEffect(() => {
    return () => {
      setSuccessOrder(undefined);
    }
  }, []);


  const servicesSummaryPrice = successOrder?.services.reduce((acc, service) => {
    return acc + service.price;
  }, 0);
  const serviceSummaryCurrency = successOrder?.services?.[0].currency;
  const totalServices = `${servicesSummaryPrice} ${serviceSummaryCurrency}`;

  const date = successOrder?.date ? dateFormat(new Date(successOrder.date), 'dd MMMM') : '';

  const time = successOrder?.timeStart || '';

  return {
    successOrder,
    isLoaded,
    setSuccessOrder,
    isPastEvent,
    date,
    time,
    totalServices
  }
}

export default useOrderByHash;