import React, { useEffect, useState} from 'react';

import queryString from 'query-string';
import {useEffectOnce} from "react-use";
import { StyledEngineProvider } from '@mui/material/styles';
// import i18n from 'i18nex';
import { I18nextProvider } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import {OrganizationDTO} from "components/types";
import Layout from "components/Layout/Layout";
import Routing from "components/Routing/Routing";
import Spinner from "components/Spinner/Spinner";
import useCustomCss from "hooks/useCustomCss";
import AuthProvider from "providers/AuthProvider";
import Notifications from "components/Notifications/Notifications";

import {apiGetOrganizationById} from 'api/organizations';

import styles from './App.module.scss'
import { BrowserRouter } from 'react-router-dom';
import DeviceProvider, { mobileCheck } from 'providers/DeviceProvider';
import cn from 'classnames';
import i18n from 'translations/i18n';
import useCustomInformation from 'hooks/useCustomInformation';
import SocialAuthPage from 'components/AuthForm/SocialAuthPage/SocialAuthPage';
import {REACT_APP_GOOGLE_OAUTH} from 'appInfo';


export const App = () => {
    // adding custom title etc.
    useCustomInformation()
    const [urlOrganization, setUrlOrganization] = useState<OrganizationDTO | null>();
    const ownerIdHash = location.hostname.split('.')[0];
    const {loading: loadingCustomStyles, inIframe } = useCustomCss({uuid: ownerIdHash});

    useEffect(() => {

        if(inIframe()) {
            // @ts-ignore
            // document?.querySelector('#root')?.remove();
            return;
        }
        //@ts-ignore
        window.scrollTo(0,5)
        //
        // document.documentElement.requestFullscreen();

    }, []);

    const parsed = queryString.parse(location.search);
    const organizationId = parsed?.organizationId;


    useEffectOnce(() => {
        if(organizationId) {
            apiGetOrganizationById(organizationId as string)
                .then(resp => {
                    setUrlOrganization(resp.data);
                })
                .catch(() => {
                    setUrlOrganization(null)
                })
        }
    });

    if(REACT_APP_GOOGLE_OAUTH && window.location.href.includes('auth?auth')) {
        return (
          <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_OAUTH}>
              <SocialAuthPage/>
          </GoogleOAuthProvider>
        )
    }

    return (
      <I18nextProvider i18n={i18n}>
          <StyledEngineProvider injectFirst>
              <div className={cn(styles.component, mobileCheck() && 'isMobile')}>
                  <BrowserRouter>
                      {(organizationId && urlOrganization === undefined  || loadingCustomStyles) && (
                        <Spinner isFullPage className={styles.spinner}/>
                      )}
                      <div className={cn(styles.loadedContentLayout, 'App_loadedContentLayout--styles')}>
                          <AuthProvider>
                              <DeviceProvider>
                                  <Layout>
                                      <Routing />
                                  </Layout>
                              </DeviceProvider>
                          </AuthProvider>
                      </div>
                      {/*{(organizationId && urlOrganization === undefined  || loadingCustomStyles)? (*/}
                      {/*    <Spinner isFullPage/>*/}
                      {/*) : (*/}
                      {/*    <div className={cn(styles.loadedContentLayout, 'App_loadedContentLayout--styles')}>*/}
                      {/*        <AuthProvider>*/}
                      {/*            <DeviceProvider>*/}
                      {/*                <Layout>*/}
                      {/*                    <Routing />*/}
                      {/*                </Layout>*/}
                      {/*            </DeviceProvider>*/}
                      {/*        </AuthProvider>*/}
                      {/*    </div>*/}
                      {/*)}*/}
                      <Notifications />
                  </BrowserRouter>
              </div>
          </StyledEngineProvider>
      </I18nextProvider>
    )
}
