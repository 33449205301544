import React from 'react';
import { FCC } from 'types';
import cn from "classnames";
import Button from '@mui/material/Button';

import { useOrganization } from 'providers/OrganizationProvider';
import {i18nt} from "translations/i18n";

import LanguageButton from 'components/Language/LanguageButton/LanguageButton';

import styles from './OrderPageHeader.module.scss'

export type OrderPageHeaderButtonType = {
    onClick: () => void,
    title?: string;
    className?: string;
}
export interface IOrderPageHeader {
    title?: string;
    backButton?: OrderPageHeaderButtonType;
    nextButton?: OrderPageHeaderButtonType;
    defaultBackButton?: boolean;
    className?: string;
    rightBar?: React.ReactNode;
}

const OrderPageHeader: FCC<IOrderPageHeader> = ({ children, className, title = i18nt('ONLINE_APPOINTMENT'), backButton, nextButton, defaultBackButton, rightBar }) => {

    const { navigateToOrganizationPage } = useOrganization();

    const handleBack = () => {

        if(backButton?.onClick) {
            backButton.onClick();
            return;
        }

        navigateToOrganizationPage();
    }

    return (
        <div className={cn(styles.component, 'OrderPageHeader--styles', className)}>
            <div className={styles.navigation}>

                <div className={cn(styles.navigationButton, styles.navigationButtonPrev, backButton?.className)}>
                    {(backButton || defaultBackButton) && (
                        <Button variant="outlined" size="small" onClick={handleBack}>
                            {`<`} {backButton?.title || i18nt('BACK')}
                        </Button>
                    )}
                </div>
                <div className={styles.title}>
                    {title}
                </div>

                <div className={cn(styles.navigationButton, styles.rightSide)}>
                    {rightBar && (
                      <div className={styles.rightBar}>
                          {rightBar}
                      </div>
                    )}
                    <div className={cn(styles.languageButtonBox)}>
                        <LanguageButton className={styles.languageButton} languageClassName={styles.languageName}/>
                    </div>
                </div>

                {/* TODO: remove next commented string. Looks like they not used*/}
                {/*<div className={cn(styles.navigationButton, styles.navigationButtonNext, nextButton?.className)}>*/}
                {/*    {nextButton && (*/}
                {/*        <Button variant="outlined" size="small" onClick={nextButton.onClick}>*/}
                {/*            {nextButton?.title || i18nt('NEXT')} {`>`}*/}
                {/*        </Button>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default OrderPageHeader;