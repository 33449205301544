import React from 'react';
import cn from 'classnames';

import {Feedbacks} from 'components/types';
import NoData from 'components/NoData/NoData';
import FeedbackList from 'components/FeddabksList/FeddabksList';

import styles from './EmployeeFeedbacks.module.scss';
import {i18nt} from 'translations/i18n';

type Props = {
  className?: string;
  feedbacks: Feedbacks;
}

const EmployeeFeedbacks = ({className, feedbacks}: Props) => {
  return (
    <div className={cn(styles.component, className)}>
      {feedbacks.count > 0 ? (
        <FeedbackList feedbacks={feedbacks.data}/>
      ):(
        <div className={styles.noDataWrapper}>
          <NoData className={styles.noData} title={i18nt('UNFORTUNATELY_THERE_ARE_NO_REVIEWS_YET')}/>
        </div>
      )}
    </div>
  )
}

export default EmployeeFeedbacks;
