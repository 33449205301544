import React, { FC } from 'react';
import cn from 'classnames';
import Highlighter from 'react-highlight-words';

import styles from './TextHighlighter.module.scss';

export interface ITextHighlighter {
    className?: string;
    classNameHighlight?: string;
    search?: string | string[];
    text?: string;
}

const TextHighlighter:FC<ITextHighlighter> = ({className, classNameHighlight, search = '', text }) => {
    if(!text) return  null;

    const searchWords = Array.isArray(search) ? search : [search]

    return (
        <span className={cn('TextHighlighter_component')}>
            <Highlighter
                highlightClassName={cn(styles.highlight, classNameHighlight)}
                className={className}
                searchWords={searchWords}
                autoEscape={true}
                textToHighlight={text}
            />
        </span>
    )
}

export default TextHighlighter;