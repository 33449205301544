import { createContext } from 'react';
import _ from 'lodash';

import {StoreProviderContextType} from './StoreProviderTypes';

const initialState = {
    date: null,
    setDate: _.noop,
    handleDate: _.noop,

    time: null,
    setTime: _.noop,
    handleTime: _.noop,

    specialist: undefined,
    setSpecialist: _.noop,
    handleSpecialist: _.noop,

    servicesList: [],
    services: [],
    setServices: _.noop,
    handleService: _.noop,

    submitUserOrder: _.noop as StoreProviderContextType['submitUserOrder'],

    availableTimeSlots: [],
    updateDateByDay: _.noop,

    specialistsList: [],

    handleOrderData: _.noop,
    handleOrderDataNoTransition: _.noop,

    successOrder: undefined,
    setSuccessOrder: _.noop,

    transitionToRoute: _.noop,

    shortOptions: false,
    singleEmployee: undefined,
    singleService: undefined,

    isLoading: true,

    setOrderData: _.noop,
    resetOrderListData: _.noop,
    resetLoadedOptions: _.noop,

};

const StoreProviderContext = createContext<StoreProviderContextType>(initialState);

export default StoreProviderContext;
