import React, { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import styles from './UserOrdersListItem.module.scss';
import { OrderDataExtended, OrganizationDTO, ServiceSubItemDTO, SpecialistDTO } from 'components/types';
import Avatar from '@mui/material/Avatar';
import { dateFormat } from 'utils/dateUtil';

export interface UserOrdersListItemProps {
    order: OrderDataExtended;
}

const UserOrdersListItem: FC<UserOrdersListItemProps> = ({order}) => {
    const location = useLocation();

    const {
        employee,
        organization,
        services,
        timeStart,
        date,
        timeEnd,
        id,
        hash,
    } = order;

    const servicesNames = services.reduce((acc: string[],service) => [...acc, service.name], []).join(',');
    const price = services.reduce((acc: number,service) => acc + service.price, 0);
    const currency = services[0].currency;

    const dateTitle = `${dateFormat(new Date(date), 'dd MMMM')} ${timeStart}`

    const employeeName = employee.name || employee.name;
    return (
        <Link className={styles.component} to={`${location.pathname}/${hash}`}>
            <div>
                <Avatar alt={employeeName} src={employee.image} className={styles.avatar}>{employeeName.substr(0,2).toUpperCase()}</Avatar>
            </div>
            <div className={styles.texts}>
                <div className={styles.servicesNames}>
                    {servicesNames}
                </div>

                <div className={styles.footer}>
                    <div className={styles.priceBlock}>
                        <span className={styles.price}>
                            {price}
                        </span>
                         <span className={styles.currency}>
                            {currency}
                        </span>

                    </div>
                    <span className={styles.date}>
                        {dateTitle}
                    </span>
                </div>
            </div>

        </Link>
    )
}

export default UserOrdersListItem;
