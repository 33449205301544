
import _ from 'lodash';

//@ts-ignore
export const toSnakeCase = obj =>
    _.isArray(obj)
        ? obj.map(item => (_.isObject(item) ? toSnakeCase(item) : item))
        : _.chain(obj)
            .toPairs()
            .map(([key, value]) => [_.snakeCase(key), _.isObject(value) ? toSnakeCase(value) : value])
            .fromPairs()
            .value();

//@ts-ignore
export const toCamelCase = obj =>
    _.isArray(obj)
        ? obj.map(item => (_.isObject(item) ? toCamelCase(item) : item))
        : _.chain(obj)
            .toPairs()
            .map(([key, value]) => [_.camelCase(key), _.isObject(value) ? toCamelCase(value) : value])
            .fromPairs()
            .value();


export const apiUppercase = toCamelCase;