import React from 'react';
import cn from 'classnames';

import {FeedbackExtended} from 'components/types';
import FeedbackListItem from 'components/FeedbackListItem/FeedbackListItem';

import styles from './FeddabksList.module.scss';

type Props = {
  className?: string;
  feedbacks: FeedbackExtended[];
}

const FeedbackList = ({className, feedbacks}: Props) => (
  <div className={cn(styles.component, className)}>
    {feedbacks.map((feedback) => (
      <FeedbackListItem feedback={feedback} key={`feedback-${feedback.id}`}/>
    ))}
  </div>
)

export default FeedbackList;
