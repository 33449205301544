import {useParams} from 'react-router-dom';

import {useStoreProviderContext} from 'providers/StoreProvider/StoreProvider';
import {useEffect, useState} from 'react';
import {getEmployeeByIdApi} from 'api/employees';
import {useOrganization} from 'providers/OrganizationProvider';

const useEmployeeData = () => {
  const params = useParams();
  const { organizationId } = useOrganization();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employeeDetails, setEmployeeDetails] = useState<any>(null);

  const { specialistsList } = useStoreProviderContext();

  const employeeIdParam = Number(params?.employeeId);

  const employee = specialistsList?.find(item => {
    return item.id === employeeIdParam
  }) || null;

  const employeeInformationId = employee?.id || null;

  useEffect(() => {
    if(!employeeInformationId) return;

    setIsLoading(true);
    getEmployeeByIdApi({organizationId, employeeId: employeeInformationId})
      .then((response) => {
        setEmployeeDetails(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

  }, [employeeInformationId])

  return {
    employeeInformation: employeeDetails,
    isLoading: isLoading || !employeeDetails && employeeDetails !== null,
  }
}

export default useEmployeeData;