import React, {useEffect, useState} from 'react';

import styles from './SocialAuthPage.module.scss';
import queryString from 'query-string';
import SocialAuthGoogle from 'components/AuthForm/SocialAuthPage/SocialAuthGoogle';
import {useAuth} from 'providers/AuthProvider';
import showNotification from 'components/Notifications/showNotification';
import {i18nt} from 'translations/i18n';
import {getValidationError} from 'constants/texts';
import {SocialAuthCallbackArgs} from 'components/AuthForm/SocialAuthPage/types';
import Icon from 'components/Icon/Icon';
import DotsLoader from 'components/DotsLoader/DotsLoader';


const SocialAuthPage = () => {
  const {setUser} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const parsed = queryString.parse(window.location.search);

  const urlRedirectUrl = parsed?.redirectUrl as string;
  const auth = parsed?.auth as 'google' | undefined;



  const callback = ({user, error}: SocialAuthCallbackArgs) => {
    if(user) {
      setUser(user);
      showNotification({type: 'success',content: i18nt('AUTHORIZATION_SUCCESSFUL')});
      window.location.href = `${urlRedirectUrl}?init_token=${user.apiToken}`;
    }
    if(error) {
      const code = error?.data.errors?.[0]?.code;
      const errorTextByCode = getValidationError(code)
      if(errorTextByCode) {
        showNotification({type: 'error',content: errorTextByCode});
        return;
      }
      showNotification({type: 'error',content: i18nt('AUTHORIZATION_ERROR')})
    }
  }

  console.log({isLoading});

  return (
    <div className={styles.component}>
      {auth === 'google' && (
        <SocialAuthGoogle
          callback={callback}
          setIsLoading={setIsLoading}
          // isLoading={isLoading}
        />
      )}

      {isLoading && (
        <>
          <div className={styles.content}>
            <Icon name="device"/>
            <div>
              <DotsLoader/>
            </div>
            {auth === 'google' && (
              <Icon name="googleColor" className={styles.googleIcon}/>
            )}


          </div>

          Loading
        </>
      )}

    </div>
  )
};

export default SocialAuthPage;
