import React, { FC } from 'react';
import cn from 'classnames';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import styles from 'components/OrderNotExisted/OrderNotExisted.module.scss';
import { FCC } from 'types';


export interface OrderNotExistedProps {
    className?: string;
}

const OrderNotExisted: FCC<OrderNotExistedProps> = ({className}) => {

    return (
        <div className={cn(styles.component, className)}>
            <div className={styles.iconWrapper}>
                <ErrorOutlineRoundedIcon className={styles.icon}/>
            </div>
            <p className={styles.text}>
                Order not existed
            </p>
        </div>
    )
}

export default OrderNotExisted;
