import React, { FC } from 'react';
import {Route, Routes} from 'react-router-dom';

import OrderPageListShort from 'components/OrderPageListShort/OrderPageListShort';
import OrderPageList from 'components/OrderPageList/OrderPageList';
import ServicesPage from 'components/pages/ServicesPage/ServicesPage';
import DatePage from 'components/pages/DatePage/DatePage';
import TimeSlots from 'components/pages/TimeSlots/TimeSlots';
import SpecialistsPage from 'components/pages/SpecialistsPage/SpecialistsPage';

interface OrderRoutesPageProps {
  // like example just date and services or only date
  shortOptions?: boolean;
}

const OrderRoutesPage: FC<OrderRoutesPageProps> = ({shortOptions}) => {
  return (
    <Routes>
      <Route
        path="specialists"
        element={<SpecialistsPage />}
      />
      <Route
        path="services"
        element={<ServicesPage />}
      />
      <Route
        path="date"
        element={<DatePage />}
      />
      <Route
        path="time"
        element={<TimeSlots />}
      />
      <Route
        path=""
        element={shortOptions ? <OrderPageListShort/> :<OrderPageList/>}
      />
    </Routes>
  )
};

export default OrderRoutesPage;
