import { useState } from 'react';


const useLoading = (defaultState?: boolean): [boolean, (value: boolean) => void]  => {
  const [isLoading, setIsLoading] = useState<boolean>(defaultState || false);

  const handleIsLoading = (value: boolean) => setIsLoading(value);

  return [isLoading, handleIsLoading];

}

export default useLoading;
