import React, {FC} from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';
import {CircularProgress} from "@mui/material";

export interface ISpinner {
    className?: string;
    isFullPage?: boolean;
}

const Spinner: FC<ISpinner> = ({ className, isFullPage}) => {
    return <div className={cn(styles.component, isFullPage && styles.isFullPage, className)}>
        <CircularProgress className={cn(styles.spinner)} />
    </div>
}

export default Spinner