
import React from 'react';

import MUITabs from '@mui/material/Tabs';
import {TabsProps} from '@mui/material/Tabs/Tabs';
import cn from 'classnames';

import styles from './Tabs.module.scss';

const Tabs = ({className, ...props}: TabsProps) => (<MUITabs className={cn(styles.component, className)} {...props}/>);

export default Tabs;