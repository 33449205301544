import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FCC } from 'types';
import _ from 'lodash';
import useApi from 'api/useApi';
import { OrganizationDTO } from 'components/types';
import { getOrganizationsApi } from 'api/organizations';
import { useNavigate, useParams } from 'react-router-dom';
import useLoading from 'hooks/useLoading';

type OrganizationsContextType = {
  isLoading: boolean;
  organizations: OrganizationDTO[],
  loadOrganizations: () => void;
  isSingleOrganization: boolean;
};
const OrganizationsProviderContextInitialState = {
  isLoading: true,
  organizations: [],
  loadOrganizations: _.noop,
  isSingleOrganization: false,
};

const OrganizationsContext = React.createContext<OrganizationsContextType>(
  OrganizationsProviderContextInitialState
);

const useOrganizationsX = () => {

}


const OrganizationsProvider: FCC = ({ children }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useLoading(true);
  const { data: organizations = [], forceRequest } = useApi<
    OrganizationDTO[]
    >(getOrganizationsApi);

  // check url and if it has organization url go to organization inner page
  useEffect(() => {
    if(organizations?.length) {
      const dbOrganizations = organizations;
      // const dbOrganizations = organizations.slice(0,1);

      if (dbOrganizations.length === 1) {
        const urlForRedirect = `/organization/${dbOrganizations[0].id}`.replaceAll(/\/\//g, '/')

        navigate(urlForRedirect);
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
  }, [organizations]);

  const value = useMemo(() => ({
    organizations: organizations || [],
    isLoading,
    loadOrganizations: forceRequest,
    isSingleOrganization: !!(organizations && organizations?.length === 1)
  }), [
    organizations,
    isLoading,
  ])

  return (
    <OrganizationsContext.Provider value={value}>
      {children}
    </OrganizationsContext.Provider>
  );
};

export const useOrganizations = () => useContext(OrganizationsContext);

export default OrganizationsProvider;
