import React from 'react'
import { ToastContainer } from 'react-toastify'
import cn from 'classnames';

import 'react-toastify/dist/ReactToastify.css'
//
// import CloseButton from './components/CloseButton'

import styles from './Notifications.module.scss'

const Notifications = () => (
    <ToastContainer
        toastClassName={styles.toast}
        bodyClassName={styles.body}
        className={cn('Notifications_component', styles.component)}
        // closeButton={CloseButton}
        closeOnClick={false}
        draggable={false}
    />
)

export default Notifications
