import React, {useState} from 'react';
import {i18nt} from 'translations/i18n';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SocialAuth from 'components/AuthForm/SocialAuth/SocialAuth';
import LoginEmailForm from '../LoginEmailForm/LoginEmailForm';

import styles from './AuthForm.module.scss';
import {REACT_APP_GOOGLE_OAUTH} from 'appInfo';

const AuthForm= () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [email, setEmail] = useState<string>('');

  return (
    <div className={styles.component}>
      <div className={styles.tabsWrapper}>
        <Tabs className={styles.Tabs} value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="basic tabs example">
          <Tab label={i18nt('LOGIN')} value={0} className={styles.Tab}/>
          <Tab label={i18nt('REGISTER')} value={1} className={styles.Tab}/>
        </Tabs>
      </div>
      <div className={styles.content}>
        <div className={styles.formStyles}>
          <div className={styles.formContent}>
            {selectedTab === 0 && (
              <LoginEmailForm onChangeEmail={setEmail} email={email}/>
            )}
            {selectedTab === 1 && (
              <LoginEmailForm isRegistrationMode onChangeEmail={setEmail} email={email}/>

            )}
          </div>
        </div>
      </div>
      {REACT_APP_GOOGLE_OAUTH && (
        <div className={styles.socialAuthWrapper}>
          <div className={styles.orSocialTitle}>
            <span/>
            <div className={styles.orSocialText}>
              or
            </div>
            <span/>
          </div>
          <SocialAuth redirectUrl={window.location.href}/>
        </div>
      )}


    </div>
  )
}

export default AuthForm;
