import React from 'react';
import { FCC } from 'types';
import SpecialistPreviewBase, {
    ISpecialistPreviewBase
} from 'components/SpecialistPreview/SpecialistPreviewBase/SpecialistPreviewBase';

import cn from "classnames";

import styles from './SpecialistPreview.module.scss';

// it is just wrapper for additional styles (like glissomorpism
// when SpecialistPreviewBase is just about data
const SpecialistPreview: FCC<ISpecialistPreviewBase> = ({ className, ...props}) => {
    return <SpecialistPreviewBase {...props} className={cn(styles.component, className)}/>
}

export default SpecialistPreview;
