const convertThemeToStyles = (data: any) => {
  // const isGlassMode = data.glassMode;
  const isGlassMode = data.themeName === 'glass';

  const glassValue = data.glassMain || 0.25;

  let rootContent = `
                    --glass-content: 0.2;     
                    --brand-color: ${data.brandColor || 'inherit'};
                    --header-background-color: ${data.headerBackgroundColor|| 'inherit'};
                    --text-additional: ${data.textColor|| 'inherit'};
                    // --header-background-image: url('http://app.gil.com.ua/image_placeholder/header.jpeg');
                    --header-background-image: url(${data.headerBackgroundImage});
                    --layout-background-image: url(${data.layoutBackgroundImage});
        `;

  if(isGlassMode) {
    rootContent += `
                --glass-main: ${glassValue};
                --glass-item: ${glassValue * 3};
            `
  }

  let stylesContent = '';

  // console.log('data.layoutBackgroundColor', data.layoutBackgroundColor)
  if(data.layoutBackgroundColor) {
    rootContent += `
                --layout-background-color: ${data.layoutBackgroundColor};
                // --layout-background-color: transparent;
            `
    if(data.layoutBackgroundColor !== '#f1f3f6' && !data.layoutBackgroundImage) {
      stylesContent += `
                .App_loadedContentLayout--styles {
                    background-image: none
                }
            `
    }
  }

  return {
    styles: `
                :root {
                    ${rootContent}
                }
                ${stylesContent}
            `,
    isGlassMode,
  }
}

export default convertThemeToStyles;
