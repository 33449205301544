import React from 'react';
import cn from 'classnames';

import { i18nt } from 'translations/i18n';
import { useOrganization } from 'providers/OrganizationProvider';
import Button from 'components/Button/Button';

import styles from 'components/pages/HomePage/HomePage.module.scss';
import FeedbackList from 'components/FeddabksList/FeddabksList';

type Props = {
  className?: string;
}

const AboutInformation = ({className}: Props) => {
  const { organization } = useOrganization();

  if(!organization) return (<div>'No organization'</div>);

  const gallery = JSON.parse(organization.gallery || '[]');

  const mapData = gallery?.find((item: any) => item.type === 'map')?.data;

  const organizationPhones = organization.phones?.filter((item) => item) || [];
  const handleOpenMap = () => {
    let googleMapsUrl;

    if(mapData?.coordinates) {
      googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${mapData?.coordinates}`;
    } else {
      googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${mapData?.address}`;
    }

    // Open the URL in a new tab
    window.open(googleMapsUrl, '_blank');
  }

  return (
    <div className={cn('AboutInformation_component', className)}>
      <div className={cn(styles.header, 'HomePage_header--styles')}>
        <div className={styles.title}>
          {organization.name}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.organizationInformation}>
          {organization.address && (
            <div className={cn(styles.lineItem, styles.addressLine)}>
              <div className={styles.addressText}>
                <div className={styles.lineItemLabel}>{i18nt('ADDRESS')}:</div>
                <div className={styles.lineContent}>
                  <div className={styles.lineItemValue}>{organization.address}</div>
                </div>
              </div>
              <Button variant="secondary" startIcon="mapPointOnMap" onClick={handleOpenMap} className={styles.mapButton}>{i18nt('ON_MAP')}</Button>
            </div>
          )}
          {organizationPhones?.length > 0 && (
            <div className={styles.lineItem}>
              <div className={styles.lineItemLabel}>{i18nt('PHONE')}:</div>
              <div className={styles.lineContent}>
                {/*{organizationPhones.map((item) => (*/}
                {/*  <div className={styles.lineItemValue} key={`key-phone-${item}`}>{item}</div>*/}
                {/*))}*/}

                {organizationPhones.map((item) => (
                  <a href={`tel:+${item}`} className={styles.lineItemValue}>
                    {`+${item}`}
                  </a>
                ))}

              </div>
            </div>
          )}
          {organization.workingHoursData && (
            <div className={styles.lineItem}>
              <div className={styles.lineItemLabel}>{i18nt('WORKING_HOURS')}:</div>
              <div className={styles.lineContent}>
                <div className={styles.lineItemValue}>{[organization.workingHoursData.start, organization.workingHoursData.end].join(' - ')}</div>
              </div>
            </div>
          )}
        </div>

        {organization.feedbacks.count > 0 && (
          <>
            <div className={styles.feedbackTitle}>
              {i18nt('REVIEWS')}
            </div>
            <FeedbackList feedbacks={organization.feedbacks.data}/>
          </>
        )}


      </div>
    </div>
  )
}

export default AboutInformation;